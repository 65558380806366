import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      sales: [],
      sale: {},
      saleNotFound: null,
      newSaleDialog: false,
    };
  },

  getters: {},

  mutations: {
    setNewSaleDialog(state, status = null) {
      state.newSaleDialog = status;
    },

    refreshSales(state, payload) {
      state.sales = [];
      state.sales = payload;
    },

    setSaleNotFound(state, status = null) {
      state.saleNotFound = status;
    },

    refreshSale(state, payload) {
      state.sale = {};
      state.sale = payload;
    },
  },

  actions: {
    getSales({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/records/sale/${branchId}/branch_sales`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setBranchNotFound", false);
            commit("refreshSales", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    getSale({ commit, rootState }, orderId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/records/sale/${orderId}/`, tokenConfig(rootState))
          .then((response) => {
            commit("setSaleNotFound", false);
            commit("refreshSale", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setSaleNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    sellOrder({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/records/sale/${payload.orderId}/sell_order/`,
            payload.data,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Order was sold",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
