import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      staff_s: [],
      staff: {},
      staffNotFound: null,

      newStaffDialog: false,
    };
  },

  getters: {},

  mutations: {
    setNewStaffDialog(state, status = null) {
      state.newStaffDialog = status;
    },

    refreshStaff_s(state, payload) {
      state.staff_s = [];
      state.staff_s = payload;
    },

    setStaffNotFound(state, status = null) {
      state.staffNotFound = status;
    },

    refreshStaff(state, payload) {
      state.staff = {};
      state.staff = payload;
    },
  },

  actions: {
    getStaff_s({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/staff/staff/${branchId}/branch_staff/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setBranchNotFound", false);
            commit("refreshStaff_s", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    getStaff({ commit, rootState }, staffId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/staff/staff/${staffId}/`, tokenConfig(rootState))
          .then((response) => {
            commit("setStaffNotFound", false);
            commit("refreshStaff", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setStaffNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    createStaff({ dispatch, commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/staff/register/`, payload, tokenConfig(rootState))
          .then((response) => {
            dispatch("getStaff_s", payload.branch);
            commit("setNewStaffDialog", false);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Staff was created successfully",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
