<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">Cart</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-show="selected.length > 0"
          @click="deleteCartProducts()"
          small
          class="error text-none my-auto mx-2"
        >
          <v-icon left small>mdi-delete</v-icon>
          Delete ({{ selected.length }})
        </v-btn>

        <v-btn
          v-show="selected.length === 0"
          small
          class="success text-none my-auto mx-2"
          :disabled="cartProducts.length < 1"
          @click="makeNewOrder()"
        >
          <v-icon small>mdi-check</v-icon>Order
          <v-divider class="mx-1 white" vertical></v-divider>
          GH₵ {{ cartProductsSum }}
        </v-btn>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-data-table
          v-model="selected"
          :fixed-header="true"
          :headers="headers"
          :items="cartProducts"
          single-expand
          item-key="id"
          show-select
          show-expand
          class="elevation-1"
          height="65vh"
          :search="search"
        >
          <template v-slot:top>
            <div class="d-flex">
              <v-spacer></v-spacer>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                color="primary darken-4"
                single-line
                hide-details
                filled
                clearable
              ></v-text-field>
            </div>

            <v-divider v-show="isCustomizedTable"></v-divider>
          </template>

          <template v-slot:item.image="{ item }">
            <v-img
              class="mx-auto"
              v-if="item.image"
              :src="item.image"
              aspect-ratio="1.7"
              height="auto"
              width="100px"
            ></v-img>

            <div class="py-2 mx-auto" style="width: 100px" v-else>
              <v-avatar size="50px" color="grey lighten-4" tile>
                <v-icon large>mdi-tag</v-icon>
              </v-avatar>
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <div class="mx-auto" v-text="item.name"></div>
          </template>

          <template v-slot:item.price="{ item }">
            <div class="mx-auto" v-text="item.price"></div>
          </template>

          <template v-slot:item.quantity="{ item }">
            <v-text-field
              v-model="item.quantity"
              filled
              color="primary darken-4"
              dense
              hide-details
              outlined
              style="width: 130px;"
              class="mx-auto"
              readonly
            >
              <template v-slot:prepend>
                <v-icon
                  class="error--text"
                  :disabled="item.quantity <= 1"
                  @click="decreaseItem(item.id)"
                >mdi-minus</v-icon>
              </template>
              <template v-slot:append-outer>
                <v-icon class="success--text" @click="increaseItem(item.id)">mdi-plus</v-icon>
              </template>
            </v-text-field>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">{{ item.description }}</td>
          </template>
        </v-data-table>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProductsCartDialog",
  data() {
    return {
      search: "",
      selected: [],

      headers: [
        { text: "Image", align: "center", value: "image" },
        { text: "Name", align: "center", value: "name" },
        { text: "Price (GH₵)", align: "center", value: "price" },
        { text: "Quantity", align: "center", value: "quantity" },
        { text: "Description", align: "center", value: "data-table-expand" }
      ]
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setProductsCartDialog", false);
    },

    increaseItem(itemId) {
      this.$store.commit("increaseCartItem", itemId);
    },

    decreaseItem(itemId) {
      this.$store.commit("decreaseCartItem", itemId);
    },

    deleteCartProducts() {
      if (this.selected) {
        this.$store.commit("deleteCartProducts", this.selected);
        this.selected = [];
      }
    },

    makeNewOrder() {
      this.$store.dispatch("makeNewOrder");
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.carts.productsCartDialog;
    },

    isCustomizedTable() {
      return true;
    },

    cartProducts() {
      return this.$store.state.carts.products;
    },

    cartProductsSum() {
      return this.$store.getters.productsCartInfo.sum;
    }
  }
};
</script>

<style>
</style>