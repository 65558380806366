<template>
  <div class="home">
    <v-container fluid>
      <v-row dense>
        <v-col v-for="vendor in vendors" :key="vendor.id" :cols="cardDimensions.flex">
          <v-card hover ripple :to="{ name: 'branches', params: { username: vendor.username } }">
            <v-img
              v-if="vendor.logo"
              :src="vendor.logo"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :height="cardDimensions.imgHeight"
            ></v-img>

            <div class="d-flex justify-center py-2 grey lighten-4" v-else>
              <v-avatar size="60px" color="primary darken-4">
                <v-icon x-large dark>mdi-store</v-icon>
              </v-avatar>
            </div>

            <v-card-title
              class="d-block text-truncate text-center font-weight-bold text-subtitle-2 py-3"
              v-text="vendor.name"
            ></v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { login } from "@/helpers/auth";

export default {
  name: "Home",
  data: () => ({
    isLoading: false,
    valid: true,
    cards: [
      {
        name: "8O8 Bistro",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg"
      },
      {
        name: "Sounder",
        src: "https://cdn.vuetifyjs.com/images/cards/road.jpg"
      }
    ]
  }),

  methods: {
    async getVendors() {
      await this.$store.dispatch("getVendors");
    }
  },

  mounted() {
    this.getVendors();
  },

  computed: {
    cardDimensions() {
      const nameType = this.$vuetify.breakpoint.name;
      var value = 0;

      switch (nameType) {
        case "xs":
          value = { flex: 6, imgHeight: "108px" };
          break;
        case "sm":
        case "md":
          value = { flex: 4, imgHeight: "200px" };
          break;
        case "lg":
        case "xl":
          value = { flex: 3, imgHeight: "200px" };
          break;

        default:
          value = { flex: 3, imgHeight: "200px" };
      }

      return value;
    },

    vendors() {
      return this.$store.state.vendors.vendors;
    }
  }
};
</script>

<style>
</style>
