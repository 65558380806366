<template>
  <div class="sale">
    <template v-if="!saleNotFound">
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="auto">
            <v-card color="white" class="pb-2" light elevation="2" max-width="600">
              <v-subheader>Sale Details</v-subheader>

              <v-container class="mt-0" fluid>
                <v-row>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Name: {{ sale.name }}</span>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Sale ID: {{ sale.id }}</span>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Attendant: {{ sale.attendant }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col class="py-0 px-0 text-center font-weight-bold" offset="9">GH₵</v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 body-2" cols="9">Amount Payable</v-col>
                      <v-col class="py-0 px-0 text-center" cols="3">{{ sale.amount_payable }}</v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 body-2" cols="9">Amount Received</v-col>
                      <v-col class="py-0 px-0 text-center" cols="3">{{ sale.amount_received }}</v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 body-2" cols="9">Balance</v-col>
                      <v-col class="py-0 px-0 text-center" cols="3">{{ sale.balance }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <div class="d-flex mr-4 mb-3">
                <v-spacer></v-spacer>

                <v-btn class="text-none grey--text text--darken-2" depressed small>
                  <v-icon left>mdi-printer-pos</v-icon>Print
                </v-btn>
              </div>

              <div class="mx-4">
                <v-data-table
                  :fixed-header="true"
                  :headers="headers"
                  :items="saleItems"
                  item-key="id"
                  class="elevation-1"
                  max-height="65vh"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      class="showLink"
                      text
                      :ripple="false"
                      :to="{ name: 'product', params: { productId: item.item_id } }"
                    >
                      <v-icon color="primary lighten-1">mdi-arrow-expand</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Sale</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Sale",
  data() {
    return {
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "Name", align: "start", value: "name" },
        { text: "Price (GH₵)", value: "price" },
        { text: "Quantity", align: "center", value: "quantity" }
      ]
    };
  },

  methods: {
    async getSale() {
      var saleId = this.$route.params.saleId;

      await this.$store.dispatch("getSale", saleId);
    }
  },

  watch: {
    $route() {
      this.getSale();
    }
  },

  mounted() {
    this.getSale();
  },

  computed: {
    sale() {
      return this.$store.state.sales.sale;
    },

    saleItems() {
      return this.$store.state.sales.sale.sale_items;
    },

    saleNotFound() {
      return this.$store.state.sales.saleNotFound;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    }
  }
};
</script>

<style scoped>
.showLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>