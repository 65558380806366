import axios from "axios";

export function login(credentials) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(credentials);

  return new Promise((resolve, reject) => {
    axios
      .post("/api/v1/users/login/", body, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function logout(state) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/v1/users/logout/", null, tokenConfig(state))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const tokenConfig = (state) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (state.token) {
    config.headers["Authorization"] = `Token ${state.token}`;
  }

  return config;
};

export function redirectToLogin(router, store) {
  store.commit("unauthenticated");
  router.push({ name: "login" });
}
