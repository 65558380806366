<template>
  <div class="header">
    <v-system-bar app v-if="false" height="2" class="pa-0 ma-0">
      <v-progress-linear class="pa-0 ma-0" indeterminate height="2" color="red"></v-progress-linear>
    </v-system-bar>

    <v-app-bar
      app
      class="pt-0 mb-0 grey--text"
      style="border-bottom: 2px solid lightgrey;"
      flat
      short
      tile
      light
      :hide-on-scroll="$vuetify.breakpoint.name === 'xs'"
    >
      <v-app-bar-nav-icon
        v-if="isAuthenticated"
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <a href="/" style="text-decoration: none; color: grey;">
        <v-toolbar-title>Vendoorr</v-toolbar-title>
      </a>

      <v-spacer></v-spacer>

      <v-toolbar-items v-if="isAuthenticated" class="hidden-sm-and-down">
        <template v-for="(link, index) in links">
          <v-hover :key="link.id" v-slot:default="{ hover }">
            <v-btn
              text
              class="text-capitalize"
              :to="link.route"
              exact-active-class="red--text"
              exact
              :color="hover ? '#fa100c' : undefined"
            >{{ link.text }}</v-btn>
          </v-hover>

          <v-divider
            v-if="index + 1 < links.length"
            vertical
            inset
            class="v-divider-vertical--red"
            :key="index"
          ></v-divider>
        </template>
      </v-toolbar-items>

      <v-spacer v-if="!xsORsmViewPort"></v-spacer>

      <!-- <v-btn icon @click="viewProductsOrder()">
        <v-badge color="primary darken-4" bordered content="0" overlap>
          <v-icon size="25">mdi-file-document-edit</v-icon>
        </v-badge>
      </v-btn>-->

      <v-btn icon @click="viewProductsCart()">
        <v-badge color="red" bordered :content="numberOfCartProducts" overlap>
          <v-icon size="25">mdi-cart</v-icon>
        </v-badge>
      </v-btn>

      <v-toolbar-items class="mr-0 pr-0">
        <v-hover v-if="isAuthenticated" class="hidden-sm-and-down" v-slot:default="{ hover }">
          <v-btn
            text
            class="text-capitalize"
            active-class="red--text"
            :color="hover ? '#fa100c' : undefined"
            @click="initiateLogout()"
          >
            <v-icon>mdi-logout</v-icon>Logout
          </v-btn>
        </v-hover>
      </v-toolbar-items>

      <v-toolbar-items v-if="!isAuthenticated && this.$route.name !== 'login'" class="mr-0 pr-0">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            text
            class="text-capitalize"
            active-class="red--text"
            :color="hover ? '#fa100c' : undefined"
            :to="{ name: 'login' }"
          >
            <v-icon>mdi-login</v-icon>Log in
          </v-btn>
        </v-hover>
      </v-toolbar-items>

      <template v-slot:extension>
        <BreadCrumbs />
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app mobile-breakpoint="600" bottom temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="red--text">
          <template v-for="link in links">
            <v-list-item :key="link.id" :to="link.route" exact-active-class="red--text" exact link>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="isAuthenticated">
            <v-list-item
              link
              class="text-capitalize"
              active-class="red--text"
              @click="initiateLogout()"
            >Logout</v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { logout } from "@/helpers/auth";

import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: "Header",
  data: () => ({
    drawer: false,
    group: null
  }),

  watch: {
    group() {
      this.drawer = false;
    }
  },

  components: {
    BreadCrumbs
  },

  methods: {
    viewProductsCart() {
      this.$store.commit("setProductsCartDialog", true);
    },

    viewProductsOrder() {
      this.$store.commit("setProductsOrderDialog", true);
    },

    async initiateLogout() {
      await logout(this.$store.state)
        .then(() => {
          this.$store.commit("unauthenticated");
          this.$router.push({ name: "login" });
          this.setSnackbar("You are logged out", "success");
        })
        .catch(error => {
          this.$store.commit("handleError", error);
        });
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  computed: {
    links() {
      return this.$store.getters.links;
    },

    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },

    xsORsmViewPort() {
      return (
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "xs"
      );
    },

    numberOfCartProducts() {
      return this.$store.getters.productsCartInfo.numberOfProducts;
    }
  }
};
</script>

<style scoped>
.v-btn--active.v-btn::before {
  background-color: #fa100c;
  color: red;
}
.v-divider-vertical--red {
  background-color: red;
  border-width: 1px;
}
</style>
