<template>
  <div class="activity">
    <v-container v-if="activity.length > 0" fluid style="max-width: 600px">
      <v-timeline align-top dense>
        <v-timeline-item v-for="event in activity" :key="event.id" small>
          <v-row>
            <v-col class="py-0" cols="12">
              <span class="blue--text dialogLink">{{ event.username }}</span>&nbsp;
              <span>{{ event.predicate }}</span>&nbsp;
              <span class="primary--text dialogLink">{{ event.object_text }}</span>
            </v-col>
            <v-col class="caption font-italic py-0 text-right" cols="12">{{ event.created }}</v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-container>

    <v-container v-else>
      <v-row justify="center">
        <v-col cols="auto">
          <div class="grey--text body-1">No Activity log(s) yet.</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Activity",
  data() {
    return {
      bottom: false
    };
  },

  watch: {
    $route() {
      this.getActivity();
    },

    bottom: "moreActivity"
  },

  methods: {
    async getActivity() {
      await this.$store.dispatch("getActivity", this.branchId);
    },

    async moreActivity() {
      if (this.bottom)
        await this.$store.dispatch("moreActivity").then(() => {
          if (this.bottomVisible()) this.moreActivity();
        });
    },

    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    }
  },

  mounted() {
    this.bottom = this.bottomVisible();
    this.getActivity();
  },

  created() {
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
  },

  computed: {
    branchId: {
      get() {
        if (this.$route.params.branchId) return this.$route.params.branchId;
        else return "";
      },

      set: function() {
        return;
      }
    },

    activity() {
      return this.$store.state.activity.activity;
    }
  }
};
</script>

<style scoped>
.dialogLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>