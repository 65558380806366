import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      vendors: [],

      vendorNotFound: null,
    };
  },

  getters: {},

  mutations: {
    refreshVendors(state, payload) {
      state.vendors = [];
      state.vendors = payload;
    },

    setVendorNotFound(state, status = null) {
      state.vendorNotFound = status;
    },
  },

  actions: {
    getVendors({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/vendors/vendor/", tokenConfig(rootState))
          .then((response) => {
            commit("refreshVendors", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
