export function initialize(store, router) {
  router.beforeEach((to, from, next) => {
    closeAllDialogs(store);

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isAuthenticated = store.state.isAuthenticated;

    if (isAuthenticated === null || isAuthenticated === false) {
      store
        .dispatch("loadUser")
        .then(() => {
          next();
        })
        .catch(() => {
          if (requiresAuth) next({ name: "login" });
          else next();
        });
    } else if (
      requiresAuth &&
      (to.name === "login" || to.name === "register") &&
      isAuthenticated === null
    ) {
      checkUser(store, to, next);
    } else if (
      (to.name === "login" || to.name === "register") &&
      isAuthenticated === true
    ) {
      if (store.state.user.user_type === "Staff")
        next({
          name: "branch",
          params: { branchId: store.state.user.native_branch },
        });
      else if (
        store.state.user.user_type === "Vendor" ||
        store.state.user.user_type === "Admin"
      )
        next({
          name: "branches",
          params: { username: store.state.user.native_vendor },
        });
      else next({ name: "home" });
    } else {
      next();
    }
  });
}

async function checkUser(store, to, next) {
  await store
    .dispatch("loadUser")
    .then(() => {
      next({ name: "branches" });
    })
    .catch(() => {
      if (to.name === "register") next({ name: "register" });
      else next({ name: "login" });
    });
}

function closeAllDialogs(store) {
  store.commit("setNewProductDialog", false);
  store.commit("setNewBranchDialog", false);
  store.commit("setNewStaffDialog", false);
  store.commit("setNewOrderDialog", false);
  store.commit("setNewSaleDialog", false);
  store.commit("setProductsOrderDialog", false);
  store.commit("setEmptyCartDialog", false);
  store.commit("setProductsCartDialog", false);
  store.commit("setNewGroupDialog", false);
  store.commit("setAddGroupProductDialog", false);
  store.commit("setEditGroupItemCostDialog", false);
  store.commit("setEditGroupTimelineDialog", false);
  store.commit("setGroupAnalysesDialog", false);
}
