<template>
  <div class="product">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card class="mx-auto" max-width="600px">
            <v-img
              v-if="productImage"
              :src="productImage"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :height="cardDimensions.imgHeight"
            ></v-img>

            <div class="d-flex justify-center py-2 grey lighten-4" style="width: 100%" v-else>
              <v-avatar size="150px" tile>
                <v-icon x-large>mdi-tag</v-icon>
              </v-avatar>
            </div>

            <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
              <v-text-field
                ref="name"
                v-model="productName"
                :rules="nameRules"
                label="Name"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                @focus="activeField('name')"
                @blur="activeField(null)"
                @input="unsavedChanges('name')"
              >
                <template v-if="isActive == 'name'" v-slot:append>
                  <v-icon
                    color="primary darken-4"
                    :disabled="isSaved.name"
                    @click="saveChanges('name')"
                  >mdi-content-save</v-icon>
                </template>
              </v-text-field>

              <v-text-field
                ref="price"
                type="number"
                v-model.number="productPrice"
                :rules="priceRules"
                prefix="GH₵"
                label="Price"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                @focus="activeField('price')"
                @blur="activeField(null)"
                @input="unsavedChanges('price')"
              >
                <template v-if="isActive == 'price'" v-slot:append>
                  <v-icon
                    color="primary darken-4"
                    :disabled="isSaved.price"
                    @click="saveChanges('price')"
                  >mdi-content-save</v-icon>
                </template>
              </v-text-field>

              <v-row>
                <v-col align="center" cols="2">
                  <v-fade-transition leave-absolute>
                    <v-progress-circular
                      v-if="loadingIsQuantifiable"
                      class="mt-0"
                      size="24"
                      color="primary darken-4"
                      indeterminate
                    ></v-progress-circular>

                    <v-checkbox
                      v-model="productIsQuantifiable"
                      color="primary darken-4"
                      hide-details
                      class="mt-0"
                      @change="saveChangesQuantifiable"
                      v-else
                    ></v-checkbox>
                  </v-fade-transition>
                </v-col>
                <v-col align="center" cols="10">
                  <v-text-field
                    ref="quantity"
                    type="number"
                    v-model.number="productQuantity"
                    :rules="quantityRules"
                    label="Quantity"
                    hint="Check the box to the left, if this product is quantifiable."
                    persistent-hint
                    color="primary darken-4"
                    :class="{ 'mb-3': !valid }"
                    outlined
                    dense
                    :disabled="!productIsQuantifiable"
                    @focus="activeField('quantity')"
                    @blur="activeField(null)"
                    @input="unsavedChanges('quantity')"
                  >
                    <template v-if="isActive == 'quantity'" v-slot:append>
                      <v-icon
                        color="primary darken-4"
                        :disabled="isSaved.quantity"
                        @click="saveChanges('quantity')"
                      >mdi-content-save</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-textarea
                ref="description"
                v-model="productDescription"
                label="Description (GH₵)"
                color="primary darken-4"
                validate-on-blur
                hide-details
                :rows="1"
                outlined
                dense
                auto-grow
                @focus="activeField('description')"
                @blur="activeField(null)"
                @input="unsavedChanges('description')"
              >
                <template v-if="isActive == 'description'" v-slot:append>
                  <v-icon
                    color="primary darken-4"
                    :disabled="isSaved.description"
                    @click="saveChanges('description')"
                  >mdi-content-save</v-icon>
                </template>
              </v-textarea>
            </v-form>

            <div class="d-flex justify-end">
              <product-delete v-model="removeProduct">
                <template #activator>
                  <v-btn class="ma-3 pa-1 text-capitalize" text color="error">
                    <v-icon>mdi-delete</v-icon>Delete
                  </v-btn>
                </template>
              </product-delete>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProductDelete from "@/components/products/ProductDelete";

export default {
  name: "Product",
  data() {
    return {
      valid: true,
      isActive: null,
      isSaved: {
        name: true,
        price: true,
        quantity: true,
        description: true
      },
      image: "",
      name: "",
      nameRules: [v => !!v || "name is required"],
      price: 0.0,
      priceRules: [
        v => !!v || "Update price",
        v => v > 0 || "Price must be greater than 0"
      ],
      loadingIsQuantifiable: false,
      quantity: 0,
      description: "",
      removeProduct: null,
      card: {
        title: "Food",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 12
      }
    };
  },

  components: {
    ProductDelete
  },

  methods: {
    async getProduct() {
      var productId = this.$route.params.productId;

      await this.$store.dispatch("getProduct", productId);
    },

    activeField(reference) {
      this.isActive = reference;
    },

    unsavedChanges(reference) {
      this.$set(this.isSaved, `${reference}`, false);
    },

    async saveChanges(reference) {
      if (!this.$refs[`${reference}`].errorBucket.length > 0) {
        var productId = this.$route.params.productId;

        await this.$store
          .dispatch("updateProduct", {
            reference,
            productId,
            data: this[`${reference}`]
          })
          .then(() => {
            this.$set(this.isSaved, `${reference}`, true);
          })
          .catch(() => {});
      }
    },

    async saveChangesQuantifiable(value) {
      this.loadingIsQuantifiable = true;
      var productId = this.$route.params.productId;

      await this.$store
        .dispatch("updateProduct", {
          reference: "is_quantifiable",
          productId,
          data: value
        })
        .then(() => {
          this.loadingIsQuantifiable = false;
        })
        .catch(() => {
          this.loadingIsQuantifiable = false;
        });
    },

    async deleteProduct() {
      if (this.removeProduct && this.removeProduct.confirmed) {
        var productId = this.$route.params.productId;

        await this.$store
          .dispatch("deleteProduct", productId)
          .then(() => {
            this.$router.push({ name: "home" });
          })
          .catch(() => {});
      }
    }
  },

  watch: {
    $route() {
      this.getProduct();
    },
    removeProduct: "deleteProduct"
  },

  mounted() {
    this.getProduct();
  },

  computed: {
    cardDimensions() {
      const nameType = this.$vuetify.breakpoint.name;
      var value = 0;

      switch (nameType) {
        case "xs":
          value = { imgHeight: "150px" };
          break;
        case "sm":
        case "md":
          value = { imgHeight: "200px" };
          break;
        case "lg":
        case "xl":
          value = { imgHeight: "200px" };
          break;

        default:
          value = { imgHeight: "200px" };
      }

      return value;
    },

    quantityRules() {
      const rules = [];

      if (this.productIsQuantifiable) {
        const rule1 = v => v !== "" || "Enter a number";
        rules.push(rule1);
        const rule2 = v => v >= 0 || "Quantity must be a non-negative number";
        rules.push(rule2);
      }

      return rules;
    },

    productImage() {
      return this.$store.state.products.product.image;
    },

    productName: {
      get() {
        return this.$store.state.products.product.name;
      },
      set: function(value) {
        this.name = value;
      }
    },

    productPrice: {
      get() {
        return this.$store.state.products.product.price;
      },
      set: function(value) {
        this.price = value;
      }
    },

    productIsQuantifiable: {
      get: function() {
        return this.$store.state.products.product.is_quantifiable;
      },
      set: function() {
        return;
      }
    },

    productQuantity: {
      get() {
        return this.$store.state.products.product.quantity;
      },
      set: function(value) {
        this.quantity = value;
      }
    },

    productDescription: {
      get() {
        return this.$store.state.products.product.description;
      },
      set: function(value) {
        this.description = value;
      }
    }
  }
};
</script>

<style>
</style>