<template>
  <v-dialog v-model="showDialog" persistent transition="dialog-transition" max-width="500px">
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">Sell Order</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-form class="pa-4" ref="form" @submit.prevent v-model="valid" lazy-validation>
          <v-text-field
            v-model.number="amountPayable"
            label="Amount Payable"
            prefix="GH₵"
            color="grey"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            readonly
          ></v-text-field>

          <v-text-field
            v-model.number="amountReceived"
            :rules="amountReceivedRules"
            autofocus
            label="Amount Received"
            prefix="GH₵"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            hint="Amount Received can't be less than Amount Payable."
            persistent-hint
            dense
          ></v-text-field>

          <v-text-field
            v-model.number="balance"
            label="Balance"
            prefix="GH₵"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            readonly
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="grey" @click="closeDialog()">Cancel</v-btn>
        <v-btn text color="success" @click="sellOrder()">Sell</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewOrderDialog",
  data() {
    return {
      valid: true,
      amountReceived: "",
      nameRules: [v => !!v || "name is required"],
      phone: "",
      phoneRules: [
        v => !isNaN(v) || "Phone number must be valid",
        v =>
          (v && v.length === 9) || v === "" || "Phone number must be 9 digits"
      ],
      branchRules: [v => !!v || "Branch ID is required. Goto a Branch"]
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setNewSaleDialog", false);
    },

    sellOrder() {
      if (this.$refs.form.validate()) {
        var orderId = this.orderId;
        var data = {
          amount_received: this.amountReceived,
          balance: this.balance
        };
        this.$store.dispatch("sellOrder", { orderId, data }).then(response => {
          this.$router.push({
            name: "sale",
            params: { saleId: response.data.id }
          });
          this.$refs.form.reset();
        });
      }
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.sales.newSaleDialog;
    },

    orderId: {
      get() {
        if (this.$route.params.orderId) return this.$route.params.orderId;
        else return "";
      },

      set: function() {
        return;
      }
    },

    amountReceivedRules() {
      const rules = [];

      const rule1 = v => !!v || "This field is required";
      rules.push(rule1);

      if (this.amountReceived) {
        const rule2 = v =>
          (!!v && v) >= this.amountPayable ||
          "Must be equivalent or greater than 'Amount Payable'";

        rules.push(rule2);
      }
      return rules;
    },

    amountPayable: {
      get() {
        return this.$store.state.orders.order.amount_payable;
      },

      set: function() {
        return;
      }
    },

    balance: {
      get() {
        return Number(this.amountReceived) -
          Number(this.$store.state.orders.order.amount_payable) >=
          0.0
          ? Number(this.amountReceived) -
              Number(this.$store.state.orders.order.amount_payable)
          : 0.0;
      },

      set: function() {
        return;
      }
    }
  }
};
</script>

<style>
</style>