<template>
  <v-dialog v-model="showDialog" persistent transition="dialog-transition" max-width="290">
    <v-card>
      <v-card-title class="headline">{{ groupItemName }}</v-card-title>

      <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
        <v-text-field
          v-model.number="groupItem.cost_price"
          type="number"
          autofocus
          label="Cost Price"
          :rules="priceRules"
          color="primary darken-4"
          prefix="GH₵"
          dense
          outlined
          style="width: 150px;"
          class="mx-auto"
          @input="updateIsSaved"
        ></v-text-field>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="grey darken-1" @click="closeDialog()" class="text-capitalize">Cancel</v-btn>

        <v-btn
          text
          color="primary darken-4"
          @click="saveCostPrice()"
          class="text-capitalize"
          :disabled="isSaved"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditGroupItemCostDialog",

  data() {
    return {
      valid: true,
      costPrice: null,
      isSaved: true,
      priceRules: [
        v => !!v || "Update price",
        v => v > 0 || "Price must be greater than 0"
      ]
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setEditGroupItemCostDialog", false);
    },

    saveCostPrice() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("updateGroupItemCostPrice", this.costPrice)
          .then(() => {
            this.isSaved = true;
            this.closeDialog();
          });
      }
    },

    updateIsSaved(value) {
      this.isSaved = false;
      this.costPrice = value;
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.analyses.editGroupItemCostDialog;
    },

    groupItemName() {
      return this.$store.state.analyses.groupItem.name;
    },

    groupItem: {
      get() {
        return this.$store.state.analyses.groupItem;
      },

      set: function(value) {
        this.costPrice = value;
      }
    }
  }
};
</script>

<style>
</style>