<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    persistent
    transition="dialog-transition"
    max-width="500px"
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">New Group</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="mr-2 text-none primary darken-4" @click="createGroup()">Create</v-btn>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-form class="pa-4" ref="form" @submit.prevent v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            autofocus
            label="Name"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            validate-on-blur
          ></v-text-field>

          <v-textarea
            v-model="description"
            label="Description"
            color="primary darken-4"
            validate-on-blur
            hide-details
            :rows="1"
            outlined
            dense
            auto-grow
          ></v-textarea>
        </v-form>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewGroupDialog",
  data() {
    return {
      valid: true,
      name: "",
      nameRules: [v => !!v || "name is required"],
      description: ""
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setNewGroupDialog", false);
      this.$refs.form.reset();
    },

    async createGroup() {
      if (this.$refs.form.validate()) {
        var data = {
          branch: this.branchId,
          name: this.name,
          description: this.description
        };

        await this.$store
          .dispatch("createGroup", data)
          .then(() => {
            this.$refs.form.reset();
          })
          .catch(error => error);
      }
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.analyses.newGroupDialog;
    },

    branchId: {
      get() {
        if (this.$route.params.branchId) return this.$route.params.branchId;
        else return "";
      },

      set: function() {
        return;
      }
    }
  }
};
</script>

<style>
</style>