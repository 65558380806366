import Vue from "vue";
import VueRouter from "vue-router";

import Product from "../views/Product.vue";
import Products from "../views/Products.vue";
// import ProductCategory from "../views/ProductCategory.vue";

import Sales from "../views/Sales.vue";
import Sale from "../views/Sale.vue";

import Credits from "../views/Credits.vue";
import Tab from "../views/Tab.vue";

import Orders from "../views/Orders.vue";
import Order from "../views/Order.vue";

import Activity from "../views/Activity.vue";

import Staff_s from "../views/Staff_s.vue";
// import Staff from "../views/Staff.vue";

import Analyses from "../views/Analyses.vue";
import ProfitsVsCostsGroups from "../views/ProfitsVsCostsGroups.vue";
import ProfitsVsCostsGroup from "../views/ProfitsVsCostsGroup.vue";

import Branches from "../views/Branches.vue";
import Branch from "../views/Branch.vue";

import Home from "../views/Home.vue";
import Account from "../views/Account.vue";
import ChangePassword from "../views/ChangePassword.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/product/:productId/edit/",
    name: "product",
    component: Product,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/product/category/:categoryId/",
  //   name: "product-category",
  //   component: ProductCategory,
  // },
  {
    path: "/branch/:branchId/products/",
    name: "products",
    component: Products,
  },

  {
    path: "/branch/:branchId/sales/",
    name: "sales",
    component: Sales,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sale/:saleId/",
    name: "sale",
    component: Sale,
  },

  {
    path: "/branch/:branchId/credits/",
    name: "credits",
    component: Credits,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tab/:tabId/",
    name: "tab",
    component: Tab,
  },

  {
    path: "/branch/:branchId/orders/",
    name: "orders",
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order/:orderId/",
    name: "order",
    component: Order,
  },

  {
    path: "/branch/:branchId/activity/",
    name: "activity",
    component: Activity,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/branch/:branchId/staff/",
    name: "staff",
    component: Staff_s,
    meta: {
      requiresAuth: true,
    },
  },

  // {
  //   path: "/staff/:staffId/",
  //   name: "staff",
  //   component: Staff,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },

  {
    path: "/branch/:branchId/analyses/",
    name: "analyses",
    component: Analyses,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/branch/:branchId/analyses/profits-vs-costs/",
    name: "profits-vs-costs-groups",
    component: ProfitsVsCostsGroups,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profits-vs-costs/:groupId/",
    name: "profits-vs-costs-group",
    component: ProfitsVsCostsGroup,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/:username/branches/",
    name: "branches",
    component: Branches,
  },
  {
    path: "/branch/:branchId/",
    name: "branch",
    component: Branch,
  },

  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/account/edit/",
    name: "edit_account",
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/password/change/",
    name: "change_password",
    component: ChangePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login/",
    name: "login",
    component: Login,
  },
  {
    path: "/register/",
    name: "register",
    component: Register,
  },
  {
    path: "*",
    name: "not_found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
