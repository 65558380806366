<template>
  <div class="login">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <v-card-title class="pa-0 mb-2">Log in</v-card-title>
            <v-form @submit.prevent ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model.trim="username"
                autofocus
                :rules="usernameRules"
                label="Username"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                :disabled="isLoading"
                validate-on-blur
                outlined
                @keypress.enter="initiateLogin()"
              ></v-text-field>

              <v-text-field
                type="password"
                v-model="password"
                :rules="passwordRules"
                label="Password"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                :disabled="isLoading"
                validate-on-blur
                outlined
                @keypress.enter="initiateLogin()"
              ></v-text-field>

              <div class="d-flex justify-end mb-3">
                <v-btn
                  outlined
                  class="text-capitalize primary--text text--darken-4"
                  @click="initiateLogin()"
                >Log In</v-btn>
              </div>

              <div class="d-flex justify-end">
                <v-btn
                  text
                  depressed
                  class="text-capitalize primary--text text--darken-4"
                  :to="{ name: 'register' }"
                >Create account</v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { login } from "@/helpers/auth";

export default {
  name: "Login",
  data: () => ({
    isLoading: false,
    valid: true,
    username: "",
    usernameRules: [v => !!v || "username is required"],
    password: "",
    passwordRules: [v => !!v || "password is required"]
  }),

  methods: {
    async initiateLogin() {
      if (this.$refs.form.validate()) {
        this.$store.commit("userLoading");

        await login({
          username: this.username,
          password: this.password
        })
          .then(response => {
            this.$store.commit("loginSuccess", response.data);
            const user = response.data.user;
            if (user.user_type == "Vendor")
              this.$router.push({
                name: "branches",
                params: { username: user.username }
              });
            else if (user.user_type == "Staff")
              this.$router.push({
                name: "branch",
                params: { branchId: user.native_branch }
              });
          })
          .catch(error => {
            this.$store.commit("handleError", error);
            this.$store.commit("unauthenticated");
          });
      }
    }
  },

  computed: {
    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    }
  }
};
</script>

<style scoped>
</style>