<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">Orders</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          small
          class="primary darken-4 text-none my-auto mx-2"
          @click="makeNewOrder()"
        >New Order</v-btn>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-data-table
          v-model="selected"
          :fixed-header="true"
          :headers="headers"
          :items="orders"
          item-key="id"
          class="elevation-1"
          height="65vh"
        >
          <template v-slot:top>
            <div class="d-flex">
              <v-spacer></v-spacer>

              <v-text-field
                v-model="orderID"
                filled
                label="Order ID"
                color="primary darken-4"
                hide-details
                clear-icon="mdi-close-circle"
                clearable
              >
                <template v-slot:append-outer>
                  <v-icon
                    v-show="orderID"
                    class="primary--text text--darken-4"
                    @click="console.log(12)"
                  >mdi-arrow-right-circle</v-icon>
                </template>
              </v-text-field>
            </div>

            <v-divider v-show="isCustomizedTable"></v-divider>
          </template>

          <template v-slot:item.actions="{}">
            <v-icon
              class="showLink"
              @click="console.log('It worked')"
              color="primary lighten-1"
            >mdi-arrow-expand</v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProductsOrderDialog",
  data() {
    return {
      orderID: "",
      selected: [],

      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "Name", align: "start", value: "name" },
        { text: "Order ID", value: "orderId" },
        { text: "Time/Date", value: "timeDate" },
        { text: "Total (GHc)", value: "amountPayable" },
        { text: "Branch", value: "branch" },
        { text: "Vendor", value: "vendor" }
      ]
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setProductsOrderDialog", false);
    },

    makeNewOrder() {
      this.$store.dispatch("makeNewOrder");
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.orders.productsOrderDialog;
    },

    isCustomizedTable() {
      return true;
    },

    orders() {
      return this.$store.state.orders.anonymousOrders;
    }
  }
};
</script>

<style>
</style>