<template>
  <div class="register">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <v-card-title class="pa-0 mb-2">Register Vendor</v-card-title>
            <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="Vendor name"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                type="text"
                v-model="username"
                :rules="usernameRules"
                label="Vendor username"
                :counter="50"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                prefix="+233"
                counter="9"
              ></v-text-field>

              <v-text-field
                type="password"
                v-model="password"
                :rules="passwordRules"
                label="Password"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                type="password"
                v-model="confirmPassword"
                :rules="confirmPasswordRules"
                label="Confirm password"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  class="text-capitalize primary--text text--darken-4"
                  @click="register()"
                >Create</v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  name: "Register",

  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      v => !!v || "Name of Vendor is required",
      v =>
        (v && v.length <= 100) || "Vendor name must be at most 100 characters"
    ],
    username: "",
    usernameRules: [
      v => !!v || "Vendor username is required",
      v =>
        !v ||
        (v && v.length <= 50) ||
        "Vendor username must be at most 50 characters"
    ],
    phone: "",
    phoneRules: [
      v => !!v || "Enter an active phone number",
      v => !isNaN(v) || "Phone number must be valid",
      v => (v && v.length === 9) || "Phone number must be 9 digits"
    ],
    password: "",
    passwordRules: [v => !!v || "password is required"],
    confirmPassword: ""
  }),

  watch: {
    password: "validatePasswords",
    confirmPassword: "validatePasswords"
  },

  methods: {
    validatePasswords() {
      return this.$refs.form.validate();
    },

    async register() {
      if (this.$refs.form.validate()) {
        const vendor = {
          name: this.name,
          username: this.username,
          phone: this.phone,
          password: this.password,
          password_confirmation: this.confirmPassword
        };

        const state = this.$store.state;

        await axios
          .post("/api/v1/vendors/register/", vendor, tokenConfig(state))
          .then(response => {
            this.$store.commit("registerSuccess", response.data);
            this.$router.push({ name: "branches" });
            this.setSnackbar("Vendor account was created", "success");
          })
          .catch(error => {
            console.log(error.response);

            this.$store.commit("handleError", error);
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  mounted() {},

  computed: {
    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    confirmPasswordRules() {
      const rules = [];
      const rule1 = v => !!v || "This field is required";
      rules.push(rule1);
      if (this.confirmPassword) {
        const rule2 = v =>
          (!!v && v) === this.password || "Passwords do not match";
        rules.push(rule2);
      }
      return rules;
    }
  }
};
</script>

<style scoped>
</style>