<template>
  <div class="branches">
    <v-container v-if="!vendorNotFound" fluid>
      <v-row dense>
        <v-col
          v-show="branches.length > 0"
          v-for="branch in branches"
          :key="branch.id"
          :cols="cardDimensions.flex"
        >
          <v-card hover ripple :to="{ name: 'branch', params: { branchId: branch.id } }">
            <v-img
              v-if="branch.logo"
              :src="branch.logo"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :height="cardDimensions.imgHeight"
            ></v-img>

            <div class="d-flex justify-center py-2 grey lighten-4" v-else>
              <v-avatar size="60px" color="primary darken-4">
                <v-icon x-large dark>mdi-source-branch</v-icon>
              </v-avatar>
            </div>

            <v-card-title
              class="d-block text-truncate text-center grey--text font-weight-bold text-subtitle-2 py-3"
              v-text="branch.name"
            ></v-card-title>
          </v-card>
        </v-col>

        <v-col v-show="branches.length <= 0">
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <div class="text-center title grey--text">
                <span class="font-weight-black">{{ $route.params.username }}</span>
                has no available branches.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">{{ $route.params.username }}</span> isn't available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-fab-transition>
      <v-btn
        v-show="isSmaller"
        dark
        color="primary darken-4"
        fixed
        bottom
        right
        fab
        @click="openNewBranchDialog()"
      >
        <v-icon large>mdi-source-branch-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
export default {
  name: "Branches",
  data() {
    return {};
  },

  components: {},

  watch: {
    $route() {
      this.getBranches();
    }
  },

  methods: {
    openNewBranchDialog() {
      this.$store.commit("setNewBranchDialog", true);
    },

    async getBranches() {
      var vendorUsername = this.$route.params.username.toString();
      await this.$store.dispatch("getBranches", vendorUsername);
    }
  },

  mounted() {
    this.getBranches();
  },

  computed: {
    cardDimensions() {
      const nameType = this.$vuetify.breakpoint.name;
      var value = 0;

      switch (nameType) {
        case "xs":
          value = { flex: 6, imgHeight: "108px" };
          break;
        case "sm":
        case "md":
          value = { flex: 4, imgHeight: "200px" };
          break;
        case "lg":
        case "xl":
          value = { flex: 3, imgHeight: "200px" };
          break;

        default:
          value = { flex: 3, imgHeight: "200px" };
      }

      return value;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    isSmaller() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs" || name === "sm") return true;
      else return false;
    },

    vendorNotFound() {
      return this.$store.state.vendors.vendorNotFound;
    },

    branches() {
      return this.$store.state.branches.branches;
    }
  }
};
</script>

<style>
</style>