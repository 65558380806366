<template>
  <div class="change-password">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <v-card-title class="pa-0 mb-2">Change password</v-card-title>
            <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
              <v-text-field
                type="password"
                v-model="oldPassword"
                :rules="oldPasswordRules"
                label="Old password"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                type="password"
                v-model="newPassword"
                :rules="newPasswordRules"
                label="New password"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <v-text-field
                type="password"
                v-model="confirmPassword"
                :rules="confirmPasswordRules"
                label="Confirm new password"
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                validate-on-blur
                outlined
              ></v-text-field>

              <div class="d-flex justify-end mb-3">
                <v-btn
                  outlined
                  class="text-capitalize primary--text text--darken-4"
                  @click="changePassword()"
                >Update Password</v-btn>
              </div>

              <div class="d-flex justify-end">
                <v-btn
                  text
                  depressed
                  class="text-capitalize primary--text text--darken-4"
                  disabled
                >Forgot password?</v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  data() {
    return {
      valid: true,
      oldPassword: "",
      oldPasswordRules: [v => !!v || "This field is required"],
      newPassword: "",
      newPasswordRules: [
        v => !!v || "This field is required",
        v => (v && v.length >= 8) || "Password must be 8 characters or more"
      ],
      confirmPassword: ""
    };
  },

  watch: {
    newPassword: "validatePasswords",
    confirmPassword: "validatePasswords"
  },

  methods: {
    validatePasswords() {
      return this.$refs.form.validate();
    },

    async changePassword() {
      if (this.validatePasswords()) {
        const state = this.$store.state;

        await axios
          .put(
            `/api/v1/users/user/change_password/`,
            {
              old_password: this.oldPassword,
              new_password: this.newPassword,
              new_password_confirmation: this.confirmPassword
            },
            tokenConfig(state)
          )
          .then(() => {
            this.$router.push({ name: "edit_account" });
            this.setSnackbar("Password was changed", "success");
          })
          .catch(error => {
            this.$store.commit("handleError", error);
            console.log(error.response);
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },
  computed: {
    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    confirmPasswordRules() {
      const rules = [];
      const rule1 = v => !!v || "This field is required";
      rules.push(rule1);
      if (this.confirmPassword) {
        const rule2 = v =>
          (!!v && v) === this.newPassword || "Passwords do not match";
        rules.push(rule2);
      }
      return rules;
    }
  }
};
</script>