import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { tokenConfig } from "@/helpers/auth";
import carts from "./Carts";
import products from "./Products";
import orders from "./Orders";
import sales from "./Sales";
import credits from "./Credits";
import staff from "./Staff";
import branches from "./Branches";
import vendors from "./Vendors";
import activity from "./Activity";
import analyses from "./Analyses";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
    user: null,
    isAuthenticated: null,
    isLoading: false,

    snackbar: false,
    snackbarColor: "",
    snackbarMessage: "",
  },

  getters: {
    links: (state) => {
      if (state.isAuthenticated && state.user.user_type === "Staff")
        return [
          {
            text: "Products",
            route: {
              name: "products",
              params: { branchId: state.user.native_branch },
            },
          },
          {
            text: "Orders",
            route: {
              name: "orders",
              params: { branchId: state.user.native_branch },
            },
          },
          {
            text: "Sales",
            route: {
              name: "sales",
              params: { branchId: state.user.native_branch },
            },
          },

          {
            text: "Activity",
            route: {
              name: "activity",
              params: { branchId: state.user.native_branch },
            },
          },
          { text: "Account", route: { name: "edit_account" } },
        ];
      else if (state.isAuthenticated && state.user.user_type === "Vendor")
        return [
          {
            text: "Branches",
            route: {
              name: "branches",
              params: { username: state.user.native_vendor },
            },
          },
          { text: "Account", route: { name: "edit_account" } },
        ];
    },

    userDetails(state) {
      return {
        id: state.user.id,
        name: state.user.name,
        username: state.user.username,
        email: state.user.email,
      };
    },
  },
  mutations: {
    updateUser(state, payload) {
      state.user = Object.assign({}, payload);
    },

    handleResponse(state, response) {
      //Set Snackbar
      state.snackbar = true;
      state.snackbarColor = response.color;
      state.snackbarMessage = response.msg;
    },

    handleError(state, error) {
      var msg;
      if (error.response) {
        var response = error.response;
        var status = response.status;

        if (status === 403) {
          if (response.data && response.data.detail) msg = response.data.detail;
          else msg = "You are restricted";
        } else if (status === 400 || status === 401) {
          if (response.data && response.data.message)
            msg = response.data.message;
          else if (response.data) msg = response.data;
          else msg = "Something went wrong";
        } else if (response.status === 500) {
          msg = "An error occurred. Try again";
        } else {
          msg = "An error occurred";
        }
      } else {
        msg = "Check connection and try again";
      }

      //Set Snackbar
      state.snackbar = true;
      state.snackbarColor = "error";
      state.snackbarMessage = msg;
    },

    setSnackbar(state, payload) {
      state.snackbar = payload.state;
      state.snackbarColor = payload.color;
      state.snackbarMessage = payload.comment;
    },

    userLoading(state) {
      state.isLoading = true;
    },

    userLoaded(state, payload) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = payload;
    },

    unauthenticated(state) {
      localStorage.removeItem("token");
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
    },

    registerSuccess(state, payload) {
      localStorage.setItem("token", payload.token);
      state.token = payload.token;
      state.user = payload.user;
      state.isAuthenticated = true;
      state.isLoading = false;
    },

    loginSuccess(state, payload) {
      localStorage.setItem("token", payload.token);
      state.token = payload.token;
      state.user = payload.user;
      state.isAuthenticated = true;
      state.isLoading = false;
    },
  },
  actions: {
    loadUser({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit("userLoading");

        axios
          .get("/api/v1/users/user/get_current_user/", tokenConfig(state))
          .then((response) => {
            commit("userLoaded", response.data);

            resolve(response);
          })
          .catch((error) => {
            commit("unauthenticated");

            reject(error);
          });
      });
    },
  },
  modules: {
    carts,
    products,
    orders,
    sales,
    credits,
    staff,
    branches,
    vendors,
    activity,
    analyses,
  },
});
