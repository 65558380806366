import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      activity: [],

      nextUrl: null,
    };
  },

  getters: {},

  mutations: {
    refreshActivity(state, payload) {
      state.activity = payload.results;
      if (payload && payload.next) state.nextUrl = payload.next.slice(21);
    },

    updateActivity(state, payload) {
      if (payload.next) state.nextUrl = payload.next.slice(21);
      else state.nextUrl = payload.next;
      state.activity.push(...payload.results);
    },
  },

  actions: {
    getActivity({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/branches/activity/${branchId}/branch_activity`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setBranchNotFound", false);
            commit("refreshActivity", response.data);

            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    moreActivity({ commit, rootState, state }) {
      return new Promise((resolve, reject) => {
        if (state.nextUrl)
          axios
            .get(state.nextUrl, tokenConfig(rootState))
            .then((response) => {
              commit("updateActivity", response.data);

              resolve(response);
            })
            .catch((error) => {
              commit("handleError", error);
              reject(error);
            });
      });
    },
  },
};
