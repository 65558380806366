<template>
  <v-dialog v-model="showDialog" persistent transition="dialog-transition" max-width="450">
    <v-card>
      <v-card-title class="title">Timeline For Analysis</v-card-title>
      <v-card-text>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From"
              color="primary darken-4"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker v-model="fromDate" @input="setTimelineFrom"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To"
              color="primary darken-4"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker v-model="toDate" @input="setTimelineTo"></v-date-picker>
        </v-menu>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="primary darken-4" @click="closeDialog()">Done</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditGroupTimelineDialog",
  data() {
    return {
      fromDate: new Date().toISOString().substr(0, 10),
      fromMenu: false,
      toDate: new Date().toISOString().substr(0, 10),
      toMenu: false
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setEditGroupTimelineDialog", false);
    },

    setTimelineFrom(value) {
      this.$store.commit("setGroupTimelineFrom", value);
      this.fromMenu = false;
    },

    setTimelineTo(value) {
      this.$store.commit("setGroupTimelineTo", value);
      this.toMenu = false;
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.analyses.editGroupTimelineDialog;
    }
  }
};
</script>

<style>
</style>