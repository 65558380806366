import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      groups: [],
      group: {},
      groupItems: [],
      groupItem: {},

      groupTimelineFrom: new Date().toISOString().substr(0, 10),
      groupTimelineTo: new Date().toISOString().substr(0, 10),

      groupNotFound: null,
      newGroupDialog: false,
      addGroupProductDialog: false,
      editGroupTimelineDialog: false,
      editGroupItemCostDialog: false,
      groupAnalysesDialog: false,
    };
  },

  getters: {
    allExemptedProducts(state, getters, rootState) {
      if (state.groupItems.length < 1) return rootState.products.products;

      var allProducts = JSON.parse(JSON.stringify(rootState.products.products));
      var productsToTrim = JSON.parse(
        JSON.stringify(rootState.products.products)
      );

      var groupItems = JSON.parse(JSON.stringify(state.groupItems));

      for (var product_out of allProducts) {
        var duplicate = groupItems.filter(
          (groupProduct) => groupProduct.product === product_out.id
        );
        productsToTrim.splice;
        if (duplicate.length) {
          productsToTrim
            .filter((product_in) => product_in.id === duplicate[0].product)
            .map((product_in) =>
              productsToTrim.splice(productsToTrim.indexOf(product_in), 1)
            );
        }
      }

      return productsToTrim;
    },
  },

  mutations: {
    setGroupNotFound(state, status = null) {
      state.groupNotFound = status;
    },

    refreshGroups(state, payload) {
      state.groups = [];
      state.groups = payload;
    },

    refreshGroup(state, payload) {
      state.group = {};
      state.group = payload;
    },

    refreshGroupItems(state, payload) {
      state.groupItems = [];
      state.groupItems = payload;
    },

    refreshGroupItem(state, payload) {
      state.groupItem = {};
      state.groupItem = Object.assign({}, state.groupItem, payload);
    },

    setNewGroupDialog(state, status = null) {
      state.newGroupDialog = status;
    },

    setAddGroupProductDialog(state, status = null) {
      state.addGroupProductDialog = status;
    },

    setEditGroupTimelineDialog(state, status = null) {
      state.editGroupTimelineDialog = status;
    },

    setGroupTimelineFrom(state, value) {
      state.groupTimelineFrom = value;
    },

    setGroupTimelineTo(state, value) {
      state.groupTimelineTo = value;
    },

    setGroupAnalysesDialog(state, status = null) {
      state.groupAnalysesDialog = status;
    },

    setEditGroupItemCostDialog(state, status = null) {
      state.editGroupItemCostDialog = status;
    },
  },

  actions: {
    getGroups({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/analyses/group/${branchId}/branch_groups/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setBranchNotFound", false);
            commit("refreshGroups", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    getGroup({ commit, rootState }, groupId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/analyses/group/${groupId}/branch_group/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setGroupNotFound", false);
            commit("refreshGroup", response.data["group"]);
            commit("refreshGroupItems", response.data["group_items"]);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setGroupNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    createGroup({ dispatch, commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/analyses/group/`, payload, tokenConfig(rootState))
          .then((response) => {
            dispatch("getGroups", response.data.branch);
            commit("setNewGroupDialog", false);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Group was created",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    addGroupItems({ dispatch, commit, rootState, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/analyses/group/${state.group.id}/add_items/`,
            { group_items: payload },
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setGroupNotFound", false);
            dispatch("getGroup", state.group.id);
            dispatch("getProducts", state.group.branch);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Group was updated",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    updateGroupItemCostPrice({ dispatch, commit, rootState, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/analyses/group/${state.groupItem.id}/update_cost_price/`,
            { cost_price: payload },
            tokenConfig(rootState)
          )
          .then((response) => {
            dispatch("getGroup", state.group.id);
            dispatch("getProducts", state.group.branch);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Group Item was updated",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    deleteGroupItem({ dispatch, commit, rootState, state }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `/api/v1/analyses/group/${state.groupItem.id}/delete_item/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            dispatch("getGroup", state.group.id);
            dispatch("getProducts", state.group.branch);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Group Item was deleted",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    loadGroupAnalyses({ commit, rootState, state }) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/analyses/group/${state.group.id}/group_analyses/`,
            {
              fromDate: state.groupTimelineFrom,
              toDate: state.groupTimelineTo,
            },
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Ready Analyses",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    updateGroup({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/analyses/group/${payload.groupId}/update_group/`,
            { [payload.reference]: payload.data },
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setGroupNotFound", false);
            commit("refreshProduct", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Group was updated",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setGroupNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    deleteGroup({ commit, rootState }, groupId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `/api/v1/analyses/group/${groupId}/delete_group/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setGroupNotFound", false);
            commit("refreshGroup", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Group was deleted",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setGroupNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
