import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      products: [],
      product: {},
      productCategoryNotFound: null,
      productNotFound: null,

      newProductDialog: false,
    };
  },

  getters: {
    products(state) {
      return state.products.map((product) => {
        if (product.is_quantifiable && product.quantity === 0) {
          product.isSelectable = false;
        } else {
          product.isSelectable = true;
        }

        return product;
      });
    },
  },

  mutations: {
    setProductCategoryNotFound(state, status = null) {
      state.productCategoryNotFound = status;
    },

    setProductNotFound(state, status = null) {
      state.productNotFound = status;
    },

    refreshProducts(state, payload) {
      state.products = [];
      state.products = payload;
    },

    refreshProduct(state, payload) {
      state.product = {};
      state.product = payload;
    },

    setNewProductDialog(state, status = null) {
      state.newProductDialog = status;
    },
  },

  actions: {
    getProducts({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/products/product/${branchId}/branch_products`,
            tokenConfig(rootState)
          )
          .then((response) => {
            // commit("setProductCategoryNotFound", false);
            commit("setBranchNotFound", false);
            commit("refreshProducts", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              // commit("setProductCategoryNotFound", true);
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    getProduct({ commit, rootState }, productId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/products/product/${productId}/`, tokenConfig(rootState))
          .then((response) => {
            commit("setProductNotFound", false);
            commit("refreshProduct", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setProductNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    updateProduct({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/products/product/${payload.productId}/update_${payload.reference}/`,
            { [payload.reference]: payload.data },
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setProductNotFound", false);
            commit("refreshProduct", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Product was updated",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setProductNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    deleteProduct({ commit, rootState }, productId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `/api/v1/products/product/${productId}/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setProductNotFound", false);
            commit("refreshProduct", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Product was deleted",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setProductNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
