<template>
  <div class="staff">
    <template v-if="!branchNotFound">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="staff_s"
        single-expand
        item-key="username"
        show-expand
        class="elevation-1"
        height="65vh"
      >
        <template v-slot:item.photo="{ item }">
          <v-img v-if="item.photo" :src="item.photo" aspect-ratio="1.7" height="auto" width="100px"></v-img>

          <div class="py-2" style="width: 100px" v-else>
            <v-avatar size="50px" color="grey lighten-4" tile>
              <v-icon large>mdi-account</v-icon>
            </v-avatar>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">{{ item.description }}</td>
        </template>
      </v-data-table>

      <v-fab-transition>
        <v-btn
          v-show="isSmaller"
          dark
          color="primary darken-4"
          fixed
          bottom
          right
          fab
          @click="openNewStaffDialog()"
        >
          <v-icon large>mdi-account-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Branch</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Staff",
  data() {
    return {
      headers: [
        { text: "Photo", value: "photo", sortable: false },
        { text: "Name", align: "start", value: "name" },
        { text: "Username", align: "start", value: "username" },
        { text: "Phone", value: "phone", sortable: false },
        { text: "Description", sortable: false, value: "data-table-expand" }
      ]
    };
  },

  components: {},

  methods: {
    async getStaff_s() {
      var branchId = this.$route.params.branchId;

      await this.$store.dispatch("getStaff_s", branchId);
    },

    openNewStaffDialog() {
      this.$store.commit("setNewStaffDialog", true);
    }
  },

  watch: {
    $route() {
      this.getStaff_s();
    }
  },

  mounted() {
    this.getStaff_s();
  },

  computed: {
    isSmaller() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs" || name === "sm") return true;
      else return false;
    },

    staff_s() {
      return this.$store.state.staff.staff_s;
    },

    branchNotFound() {
      return this.$store.state.branches.branchNotFound;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    }
  }
};
</script>

<style>
</style>