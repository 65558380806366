<template>
  <div class="account">
    <v-container class="fill-height pa-0 ma-0" fluid style="width: 100vw;">
      <v-row no-gutters align="center" justify="center">
        <v-col class="grey--text" cols="12">
          <div class="d-flex mb-10 mt-5 justify-center">
            <v-btn
              outlined
              class="text-capitalize primary--text text--darken-4"
              :to="{name: 'change_password'}"
            >Change Password</v-btn>
          </div>

          <v-spacer vertical></v-spacer>

          <div class="d-flex mt-10 mb-5 justify-center">
            <v-btn
              outlined
              class="text-capitalize primary--text text--darken-4"
              disabled
            >Reset Password</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  name: "Account",

  data: () => ({
    isLoading: false,
    valid: true,
    name: "",
    nameRules: [
      v => !!v || "Your name is required",
      v => (v && v.length <= 100) || "Your name must be at most 100 characters"
    ],
    username: "",
    usernameRules: [
      v => !!v || "Your username is required",
      v =>
        !v ||
        (v && v.length <= 50) ||
        "Your username must be at most 50 characters"
    ],
    email: "",
    emailRules: [
      v => !!v || "Email is required",
      v => /.+@.+/.test(v) || "Email must be valid"
    ],
    password: "",
    passwordRules: [v => !!v || "password is required"]
  }),

  methods: {
    async updateUser() {
      if (this.$refs.form.validate()) {
        const user = {
          name: this.name,
          username: this.username,
          email: this.email,
          password: this.password
        };

        const state = this.$store.state;
        const id = this.$store.state.user.id;

        await axios
          .put(`/api/users/${id}/`, user, tokenConfig(state))
          .then(response => {
            this.password = "";
            this.$store.commit("updateUser", response.data.data);
            this.setSnackbar("Account was updated", "success");
          })
          .catch(error => {
            this.$store.commit("handleError", error);
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  mounted() {
    this.name = this.$store.getters.userDetails.name;
    this.username = this.$store.getters.userDetails.username;
    this.email = this.$store.getters.userDetails.email;
  },

  computed: {
    userName: {
      get() {
        return this.$store.getters.userDetails.name;
      },
      set: function(name) {
        this.name = name;
      }
    },

    userUsername: {
      get() {
        return this.$store.getters.userDetails.username;
      },
      set: function(username) {
        this.username = username;
      }
    },

    userEmail: {
      get() {
        return this.$store.getters.userDetails.email;
      },
      set: function(email) {
        this.email = email;
      }
    }
  }
};
</script>

<style scoped>
.background {
  opacity: 0.6;
}
.purple-color {
  color: rgb(3, 3, 51);
}
</style>