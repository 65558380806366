<template>
  <div class="tab">
    <template v-if="!creditNotFound">
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="auto">
            <v-card color="white" class="pb-2" light elevation="2" max-width="600">
              <v-subheader>Tab Details</v-subheader>

              <v-container class="mt-0" fluid>
                <v-row>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Name: {{ tab.name }}</span>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Date Created: {{ tab.created }}</span>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Date Modified: {{ tab.modified }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col class="py-0 px-0 text-center font-weight-bold" offset="9">GH₵</v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 body-2" cols="9">Total</v-col>
                      <v-col class="py-0 px-0 text-center" cols="3">{{ tab.total }}</v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 body-2" cols="9">==</v-col>
                      <v-col class="py-0 px-0 text-center" cols="3"></v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 body-2" cols="9">==</v-col>
                      <v-col class="py-0 px-0 text-center" cols="3"></v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <div class="d-flex mr-4 mb-3">
                <v-spacer></v-spacer>

                <v-btn class="text-none grey--text text--darken-2" depressed small>
                  <v-icon left>mdi-printer-pos</v-icon>Print
                </v-btn>
              </div>

              <div class="mx-4">
                <v-data-table
                  :fixed-header="true"
                  :headers="headers"
                  :items="tabRecords"
                  item-key="id"
                  class="elevation-1"
                  max-height="65vh"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      class="showLink"
                      text
                      :ripple="true"
                      :loading="isLoading"
                      :disabled="isLoading"
                      @click="sellRecord(item.id)"
                    >
                      Sell
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Tab</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Sale",
  data() {
    return {
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "Description", align: "start", value: "description" },
        { text: "Value (GH₵)", value: "value" },
        { text: "", align: "center", value: "quantity" }
      ],

      isLoading: false,
    };
  },

  methods: {
    async getTab() {
      var tabId = this.$route.params.tabId;

      await this.$store.dispatch("getTab", tabId);
    },

    async sellRecord(itemId) {
      this.isLoading = true;
      await this.$store
        .dispatch("sellRecord", itemId)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        })
        .finally(() => {
          this.getTab()
        })
    },
  },

  watch: {
    $route() {
      this.getTab();
    }
  },

  mounted() {
    this.getTab();
  },

  computed: {
    tab() {
      return this.$store.state.credits.tab;
    },

    tabRecords() {
      return this.$store.state.credits.tab.records;
    },

    creditNotFound() {
      return this.$store.state.credits.creditNotFound;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    }
  }
};
</script>

<style scoped>
.showLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>