<template>
  <v-dialog v-model="showDialog" persistent transition="dialog-transition" max-width="450">
    <v-card>
      <v-card-title class="text-title">
        Group Analysis
        <span class="d-inline-block ml-auto">
          <v-btn @click="loadGroupAnalyses()" icon>
            <v-icon>mdi-database-refresh</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text>
        <div class="text-subtitle-2 grey--text">From: {{ fromDate }}</div>

        <div class="text-subtitle-2 grey--text">To: {{ toDate }}</div>

        <div class="text-subtitle-1 grey--text">Total Revenue: GH₵ {{ totalRevenue }}</div>

        <div class="text-subtitle-1 grey--text">
          Total Cost:
          <span class="error--text">GH₵ {{ totalCost }}</span>
        </div>

        <div class="text-subtitle-1 grey--text">
          Total Profit:
          <span class="success--text">GH₵ {{ totalProfit }}</span>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="primary darken-4" @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GroupAnalysesDialog",
  data() {
    return {
      fromDate: "Unavailable",
      toDate: "Unavailable",
      totalRevenue: "Unavailable",
      totalCost: "Unavailable",
      totalProfit: "Unavailable"
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setGroupAnalysesDialog", false);
    },

    loadGroupAnalyses() {
      this.$store.dispatch("loadGroupAnalyses").then(response => {
        this.fromDate = response.data.from_date;
        this.toDate = response.data.to_date;
        this.totalRevenue = response.data.total_revenue;
        this.totalCost = response.data.total_cost;
        this.totalProfit = response.data.total_profit;
      });
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.analyses.groupAnalysesDialog;
    }
  }
};
</script>

<style>
</style>