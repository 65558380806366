import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      credits: [],
      tab: {},
      creditNotFound: null,
      newCreditDialog: false,
    };
  },

  getters: {},

  mutations: {
    setNewCreditDialog(state, status = null) {
      state.newCreditDialog = status;
    },

    refreshCredits(state, payload) {
      state.credits = [];
      state.credits = payload;
    },

    setCreditNotFound(state, status = null) {
      state.creditNotFound = status;
    },

    refreshTab(state, payload) {
      state.tab = {};
      state.tab = payload;
    },
  },

  actions: {
    getCredits({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v2/records/credit/${branchId}/branch_credit_tabs/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setBranchNotFound", false);
            commit("refreshCredits", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    getTab({ commit, rootState }, tabId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v2/records/credit/${tabId}/`, tokenConfig(rootState))
          .then((response) => {
            commit("setCreditNotFound", false);
            commit("refreshTab", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setCreditNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    sellRecord({ commit, rootState }, recordId) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v2/records/credit/${recordId}/sell_record/`,
            {},
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Record was sold",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
