<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    persistent
    transition="dialog-transition"
    max-width="500px"
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">New Branch</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="mr-2 text-none primary darken-4" @click="createBranch()">Create</v-btn>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-form class="pa-4" ref="form" @submit.prevent v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            autofocus
            label="Name"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            validate-on-blur
          ></v-text-field>

          <v-text-field
            v-model="location"
            :rules="locationRules"
            label="Location"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            validate-on-blur
          ></v-text-field>

          <v-textarea
            v-model="description"
            label="Description"
            color="primary darken-4"
            validate-on-blur
            hide-details
            :rows="1"
            outlined
            dense
            auto-grow
          ></v-textarea>
        </v-form>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { tokenConfig, redirectToLogin } from "@/helpers/auth";

export default {
  name: "NewBranchDialog",
  data() {
    return {
      valid: true,
      name: "",
      nameRules: [v => !!v || "name is required"],
      location: "",
      locationRules: [v => !!v || "location is required"],
      description: ""
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setNewBranchDialog", false);
      this.$refs.form.reset();
    },

    async createBranch() {
      if (this.$refs.form.validate()) {
        var branch = {
          name: this.name,
          location: this.location,
          description: this.description
        };
        const state = this.$store.state;

        await axios
          .post(
            "/api/v1/branches/branch/create_branch/",
            branch,
            tokenConfig(state)
          )
          .then(() => {
            this.setSnackbar("Branch was created successfully", "success");
            this.closeDialog();
            this.$store.dispatch("getBranches", this.$route.params.username);
          })
          .catch(error => {
            if (error.response.status == 401)
              redirectToLogin(this.$router, this.$store);
            else this.$store.commit("handleError", error);
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.branches.newBranchDialog;
    }
  }
};
</script>

<style>
</style>