<template>
  <div class="d-inline-flex pa-0 ma-0" v-if="!isSmaller" style="width: 100%;">
    <!-- <v-breadcrumbs
      class="pt-0 pb-1 px-0"
      :items="breadcrumbs"
      large
      style="flex-wrap: nowrap; overflow-x: auto; width: 85vw;"
    >
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>-->

    <div class="d-flex mr-auto text-h6 text-capitalize">{{ $route.name }}</div>

    <v-divider class="mx-3" vertical></v-divider>

    <div class="d-flex my-auto" style="width: 15vw;">
      <template v-if="$route.name === 'staff'">
        <v-btn depressed class="primary darken-4 text-none mx-auto" @click="openNewStaffDialog()">
          <v-icon left>mdi-account-plus</v-icon>
          <span class="text-capitalize">Add Staff</span>
        </v-btn>
      </template>

      <template v-else-if="$route.name === 'branches'">
        <v-btn depressed class="primary darken-4 text-none mx-auto" @click="openNewBranchDialog()">
          <v-icon left>mdi-source-branch-plus</v-icon>
          <span class="text-capitalize">Add Branch</span>
        </v-btn>
      </template>

      <template v-else-if="$route.name === 'products' & !$store.state.vendors.vendorNotFound">
        <v-btn depressed class="primary darken-4 text-none mx-auto" @click="openNewProductDialog()">
          <v-icon left>mdi-tag-plus</v-icon>
          <span class="text-capitalize">Add Product</span>
        </v-btn>
      </template>

      <template v-else-if="$route.name === 'orders' & !$store.state.vendors.vendorNotFound">
        <v-btn depressed class="primary darken-4 text-none mx-auto" @click="makeNewOrder()">
          <v-icon left>mdi-file-document-edit</v-icon>
          <span class="text-capitalize">New Order</span>
        </v-btn>
      </template>

      <template
        v-else-if="$route.name === 'profits-vs-costs-groups' & !$store.state.vendors.vendorNotFound"
      >
        <v-btn depressed class="primary darken-4 text-none mx-auto" @click="openNewGroupDialog()">
          <v-icon left>mdi-select-group</v-icon>
          <span class="text-capitalize">New Group</span>
        </v-btn>
      </template>

      <template
        v-else-if="$route.name === 'profits-vs-costs-group' & !$store.state.vendors.vendorNotFound"
      >
        <v-btn
          depressed
          class="primary darken-4 text-none mx-auto"
          @click="openAddGroupProductDialog()"
        >
          <v-icon left>mdi-tag-plus</v-icon>
          <span class="text-capitalize">Add Product</span>
        </v-btn>
      </template>
    </div>
  </div>

  <div v-else class="d-flex mr-auto text-h6 text-capitalize">{{ $route.name }}</div>

  <!-- <v-breadcrumbs
    v-else
    class="pt-0 pb-1 px-0"
    :items="breadcrumbs"
    large
    style="flex-wrap: nowrap; overflow-x: auto; width: 100%;"
  >
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>-->
</template>

<script>
export default {
  name: "BreadCrumbs",
  data() {
    return {
      breadcrumbs: [
        {
          text: "Ayeduase",
          to: { name: "login" },
          exact: true
        },
        {
          text: "Products",
          to: { name: "edit_account" },
          exact: true
        },

        {
          text: "Desserts",
          to: { name: "edit_account" },
          exact: true
        },
        {
          text: "Ayeduase",
          to: { name: "login" },
          exact: true
        },
        {
          text: "Products",
          to: { name: "edit_account" },
          exact: true
        },

        {
          text: "Desserts",
          to: { name: "edit_account" },
          exact: true
        },
        {
          text: "Ayeduase",
          to: { name: "login" },
          exact: true
        },
        {
          text: "Products",
          to: { name: "edit_account" },
          exact: true
        },

        {
          text: "Desserts",
          to: { name: "edit_account" }
        },
        {
          text: "Ayeduase",
          to: { name: "login" }
        },
        {
          text: "Products",
          to: { name: "edit_account" }
        },

        {
          text: "Desserts",
          to: { name: "edit_account" }
        }
      ]
    };
  },

  methods: {
    openAddGroupProductDialog() {
      this.$store.commit("setAddGroupProductDialog", true);
    },

    openNewGroupDialog() {
      this.$store.commit("setNewGroupDialog", true);
    },

    openNewStaffDialog() {
      this.$store.commit("setNewStaffDialog", true);
    },

    openNewBranchDialog() {
      this.$store.commit("setNewBranchDialog", true);
    },

    openNewProductDialog() {
      this.$store.commit("setNewProductDialog", true);
    },

    makeNewOrder() {
      this.$store.dispatch("makeNewOrder");
    }
  },

  computed: {
    isSmaller() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs" || name === "sm") return true;
      else return false;
    }
  }
};
</script>

<style>
.v-application--is-ltr .v-banner__wrapper {
  padding-left: 0px;
}
.v-application--is-ltr .v-banner--is-mobile .v-banner__wrapper {
  padding-left: 0px;
}
</style>