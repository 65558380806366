import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      orders: [],
      order: {},
      orderNotFound: null,
      anonymousOrders: localStorage.getItem("orderIDs")
        ? localStorage.getItem("orderIDs")
        : [
            {
              name: "Emmanuel",
              orderId: "a6a7cf18-ab41-4ba8-bdbb-113545e63d27",
              timeDate: "Sun, 11 June, 2020, 19:44",
              amountPayable: 299,
              branch: "8O8 Bistro (Ahodwo)",
              vendor: "8O8 Bistro",
            },
          ],

      productsOrderDialog: false,
      newOrderDialog: false,
    };
  },

  getters: {},

  mutations: {
    setProductsOrderDialog(state, status = null) {
      state.productsOrderDialog = status;
    },

    setNewOrderDialog(state, status = null) {
      state.newOrderDialog = status;
    },

    refreshOrders(state, payload) {
      state.orders = [];
      state.orders = payload;
    },

    setOrderNotFound(state, status = null) {
      state.orderNotFound = status;
    },

    refreshOrder(state, payload) {
      state.order = {};
      state.order = payload;
    },
  },

  actions: {
    makeNewOrder({ commit, rootState }) {
      if (rootState.carts.products.length < 1)
        commit("setEmptyCartDialog", true);
      else commit("setNewOrderDialog", true);
    },

    updateExistingOrder({ dispatch, commit, rootState }) {
      if (rootState.carts.products.length < 1)
        commit("setEmptyCartDialog", true);
      else dispatch("addOrderItems");
    },

    getOrders({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/records/order/${branchId}/branch_orders`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setBranchNotFound", false);
            commit("refreshOrders", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    getOrder({ commit, rootState }, orderId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/records/order/${orderId}/`, tokenConfig(rootState))
          .then((response) => {
            commit("setOrderNotFound", false);
            commit("refreshOrder", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setOrderNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    sendOrder({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/api/v1/records/order/new_order/`,
            payload,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("clearCartProducts");
            commit("setNewOrderDialog", false);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Order was sent",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    addOrderItems({ dispatch, commit, getters, rootState, state }) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/records/order/${state.order.id}/add_items/`,
            {
              order_items: rootState.carts.products.map((product) => {
                product["item_id"] = product["id"];

                return product;
              }),
              sum: getters.productsCartInfo.sum,
            },
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("clearCartProducts");
            dispatch("getOrder", state.order.id);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Order was updated",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    deleteOrder({ commit, rootState }, orderId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/records/order/${orderId}/`, tokenConfig(rootState))
          .then((response) => {
            commit("setOrderNotFound", false);
            commit("refreshOrder", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Order was deleted",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setOrderNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    deleteOrderItem({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/records/order/${payload.orderId}/delete_item/`,
            {
              item_id: payload.itemId,
            },
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("refreshOrder", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Ordered Item was removed",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    readyOrder({ commit, rootState }, orderId) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/records/order/${orderId}/ready_order/`,
            {},
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("refreshOrder", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Order is ready for sale",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },

    setItemStatus({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/api/v1/records/order/${payload.orderId}/unset_item_pending/`,
            {
              item_id: payload.itemId,
            },
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("refreshOrder", response.data);
            commit("setSnackbar", {
              state: true,
              color: "success",
              comment: "Ordered Item status was updated",
            });
            resolve(response);
          })
          .catch((error) => {
            commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
