<template>
  <div class="branch">
    <v-subheader>{{ branchName }}</v-subheader>

    <v-container v-if="!branchNotFound" fluid>
      <v-row dense>
        <v-col v-for="card in cards" :key="card.name" :cols="cardDimensions.flex">
          <v-card
            class="pt-2"
            hover
            shaped
            ripple
            :to="{ name: card.name, params: { branchId: $route.params.branchId }}"
          >
            <v-row justify="center">
              <v-avatar size="54px" color="primary darken-4">
                <v-icon large dark>{{ card.icon }}</v-icon>
              </v-avatar>
            </v-row>

            <v-card-title
              class="d-block text-truncate text-center grey--text font-weight-bold text-subtitle-2 py-3"
              v-text="card.title"
            ></v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Branch</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Branch",
  data() {
    return {};
  },

  components: {},

  methods: {
    async getBranch() {
      var branchId = this.$route.params.branchId;

      await this.$store.dispatch("getBranch", branchId);
    }
  },

  watch: {
    $route() {
      this.getBranch();
    }
  },

  mounted() {
    this.getBranch();
  },

  computed: {
    cards() {
      return [
        // {
        //   title: "Products",
        //   icon: "mdi-tag-multiple",
        //   name: "products"
        // },
        // {
        //   title: "Orders",
        //   icon: "mdi-file-document-edit",
        //   name: "orders"
        // },
        {
          title: "Credits",
          icon: "mdi-text-box",
          name: "credits"
        },
        // {
        //   title: "Sales",
        //   icon: "mdi-text-box-check",
        //   name: "sales"
        // },
        // {
        //   title: "Staff",
        //   icon: "mdi-account-multiple",
        //   name: "staff"
        // },
        // {
        //   title: "Activity",
        //   icon: "mdi-history",
        //   name: "activity"
        // },
        // {
        //   title: "Analyses",
        //   icon: "mdi-chart-line",
        //   name: "analyses"
        // }
        // {
        //   title: "About",
        //   icon: "mdi-information",
        //   name: "about"
        // }
      ];
    },

    cardDimensions() {
      const nameType = this.$vuetify.breakpoint.name;
      var value = 0;

      switch (nameType) {
        case "xs":
          value = { flex: 6, imgHeight: "108px" };
          break;
        case "sm":
        case "md":
          value = { flex: 4, imgHeight: "200px" };
          break;
        case "lg":
        case "xl":
          value = { flex: 3, imgHeight: "200px" };
          break;

        default:
          value = { flex: 3, imgHeight: "200px" };
      }

      return value;
    },

    branchNotFound() {
      return this.$store.state.branches.branchNotFound;
    },

    branchName() {
      return this.$store.state.branches.branch.name;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    }
  }
};
</script>

<style>
</style>