import axios from "axios";
import { tokenConfig } from "@/helpers/auth";

export default {
  state() {
    return {
      branchNotFound: null,
      branches: [],
      branch: {},

      newBranchDialog: false,
    };
  },

  getters: {},

  mutations: {
    setBranchNotFound(state, status = null) {
      state.branchNotFound = status;
    },

    setNewBranchDialog(state, status = null) {
      state.newBranchDialog = status;
    },

    refreshBranches(state, payload) {
      state.branches = [];
      state.branches = payload;
    },

    refreshBranch(state, payload) {
      state.branch = {};
      state.branch = payload;
    },

    clearBranches(state) {
      state.branches = null;
    },
  },

  actions: {
    getBranches({ commit, rootState }, username) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/v1/branches/branch/${username}/vendor_branches/`,
            tokenConfig(rootState)
          )
          .then((response) => {
            commit("setVendorNotFound", false);
            commit("refreshBranches", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setVendorNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },

    getBranch({ commit, rootState }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/branches/branch/${branchId}/`, tokenConfig(rootState))
          .then((response) => {
            commit("setBranchNotFound", false);
            commit("refreshBranch", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404)
              commit("setBranchNotFound", true);
            else commit("handleError", error);
            reject(error);
          });
      });
    },
  },
};
