<template>
  <div class="profits-vs-costs-group">
    <template v-if="!branchNotFound">
      <v-data-table
        v-model="selected"
        :fixed-header="true"
        :headers="headers"
        :items="groupItems"
        item-key="id"
        class="elevation-1"
        height="65vh"
        :search="search"
      >
        <template v-slot:top>
          <div class="d-flex">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              color="primary darken-4"
              single-line
              hide-details
              filled
              clearable
            ></v-text-field>

            <v-row align-content="center" dense>
              <v-col cols="12">
                <v-row justify="end" dense>
                  <v-btn @click="openEditGroupTimelineDialog()" icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>

                  <v-btn @click="openGroupAnalysesDialog()" icon>
                    <v-icon color="success">mdi-google-analytics</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <v-divider></v-divider>
        </template>

        <template v-slot:item.name="{ item }">
          <div class="mx-auto" v-text="item.name"></div>
        </template>

        <template v-slot:item.cost_price="{ item }">
          <v-text-field
            v-model="item.cost_price"
            filled
            color="primary darken-4"
            dense
            hide-details
            outlined
            style="width: 130px;"
            class="mx-auto"
            readonly
          >
            <template v-slot:append-outer>
              <v-btn @click="openEditGroupItemCostDialog(item)" icon>
                <v-icon class="success--text">mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </template>

        <template v-slot:item.delete="{ item }">
          <item-delete v-model="removeItem">
            <template #activator>
              <v-btn @click="setGroupItem(item)" icon>
                <v-icon color="error">mdi-close-circle</v-icon>
              </v-btn>
            </template>
          </item-delete>
        </template>
      </v-data-table>

      <v-fab-transition>
        <v-btn
          v-show="isSmaller"
          dark
          color="primary darken-4"
          fixed
          bottom
          right
          fab
          @click="openAddGroupProductDialog()"
        >
          <v-icon large>mdi-tag-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Group</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ItemDelete from "@/components/analyses/ItemDelete.vue";

export default {
  name: "ProfitsVsCostsGroup",
  data() {
    return {
      search: "",
      selected: [],
      itemId: "",
      removeItem: null,

      headers: [
        { text: "Name", align: "center", value: "name" },
        { text: "Cost Price (GH₵)", align: "center", value: "cost_price" },
        {
          text: "Selling Price (GH₵)",
          align: "center",
          value: "selling_price"
        },
        { text: "", value: "delete", sortable: false }
      ]
    };
  },

  components: {
    ItemDelete
  },

  methods: {
    openAddGroupProductDialog() {
      this.$store.commit("setAddGroupProductDialog", true);
    },

    openEditGroupTimelineDialog() {
      this.$store.commit("setEditGroupTimelineDialog", true);
    },

    openGroupAnalysesDialog() {
      this.$store.commit("setGroupAnalysesDialog", true);
    },

    openEditGroupItemCostDialog(item) {
      this.setGroupItem(item);
      this.$store.commit("setEditGroupItemCostDialog", true);
    },

    async getProducts() {
      var branchId = this.$store.state.analyses.group.branch;

      await this.$store.dispatch("getProducts", branchId);
    },

    async getGroup() {
      var groupId = this.$route.params.groupId;

      await this.$store.dispatch("getGroup", groupId).then(() => {
        this.getProducts();
      });
    },

    setGroupItem(item) {
      this.$store.commit("refreshGroupItem", null);
      this.$store.commit("refreshGroupItem", item);
    },

    async deleteItem() {
      if (this.removeItem && this.removeItem.confirmed) {
        await this.$store
          .dispatch("deleteGroupItem")
          .then(() => {})
          .catch(() => {});
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  watch: {
    $route() {
      this.getGroup();
    },

    removeItem: "deleteItem"
  },

  mounted() {
    this.getGroup();
  },

  computed: {
    branchNotFound() {
      return this.$store.state.branches.branchNotFound;
    },

    groupItems() {
      return this.$store.state.analyses.groupItems;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    isSmaller() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs" || name === "sm") return true;
      else return false;
    }
  }
};
</script>

<style>
</style>