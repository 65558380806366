<template>
  <div class="sales">
    <template v-if="!branchNotFound">
      <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="sales"
        item-key="id"
        class="elevation-1"
        height="65vh"
        :search="search"
      >
        <template v-slot:top>
          <div class="d-flex">
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              filled
              clearable
            ></v-text-field>
          </div>

          <v-divider></v-divider>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            class="showLink"
            text
            :ripple="false"
            :to="{ name: 'sale', params: { saleId: item.id } }"
          >
            <v-icon color="primary lighten-1">mdi-arrow-expand</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Branch</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Sales",
  data() {
    return {
      search: "",
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "Name", align: "start", value: "name" },
        { text: "Sale ID", value: "id" },
        { text: "Time/Date", value: "created" },
        { text: "Total (GH₵)", value: "amount_payable" },
        { text: "Branch", value: "branch" },
        { text: "Vendor", value: "vendor" }
      ]
    };
  },

  methods: {
    async getSales() {
      var branchId = this.$route.params.branchId;

      await this.$store.dispatch("getSales", branchId);
    }
  },

  watch: {
    $route() {
      this.getSales();
    }
  },

  mounted() {
    this.getSales();
  },

  computed: {
    isSmaller() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs" || name === "sm") return true;
      else return false;
    },

    sales() {
      return this.$store.state.sales.sales;
    },

    branchNotFound() {
      return this.$store.state.branches.branchNotFound;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    }
  }
};
</script>

<style scoped>
.showLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>