<template>
  <div class="products">
    <template v-if="!branchNotFound">
      <v-data-table
        v-model="selected"
        :fixed-header="true"
        :headers="headers"
        :items="products"
        single-expand
        item-key="id"
        show-select
        show-expand
        class="elevation-1"
        height="65vh"
        :search="search"
      >
        <template v-slot:top>
          <div class="d-flex">
            <v-btn
              v-show="selected.length > 0"
              small
              class="primary darken-4 text-none my-auto mx-2"
              @click="addToCart()"
            >Add to Cart</v-btn>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              color="primary darken-4"
              single-line
              hide-details
              filled
              clearable
            ></v-text-field>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            class="showLink"
            text
            :ripple="false"
            :to="{ name: 'product', params: { productId: item.id } }"
          >
            <v-icon color="primary lighten-1">mdi-arrow-expand</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.image"
            src="https://picsum.photos/510/300?random"
            aspect-ratio="1.7"
            height="auto"
            width="100px"
          ></v-img>

          <div class="py-2" style="width: 100px" v-else>
            <v-avatar size="50px" color="grey lighten-4" tile>
              <v-icon large>mdi-tag</v-icon>
            </v-avatar>
          </div>
        </template>

        <template v-slot:item.isQuantifiable="{ item }">
          <v-simple-checkbox v-model="item.isQuantifiable" disabled></v-simple-checkbox>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">{{ item.description }}</td>
        </template>
      </v-data-table>

      <v-fab-transition>
        <v-btn
          v-show="isSmaller"
          dark
          color="primary darken-4"
          fixed
          bottom
          right
          fab
          @click="openNewProductDialog()"
        >
          <v-icon large>mdi-tag-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Branch</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Products",
  data() {
    return {
      search: "",
      selected: [],

      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "Image", sortable: false, value: "image" },
        {
          text: "Name",
          align: "start",
          value: "name"
        },
        { text: "Price (GH₵)", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "Description", value: "data-table-expand" }
      ]
    };
  },

  components: {},

  methods: {
    addToCart() {
      if (this.selected.length > 0) {
        this.$store.commit("addToProductsCart", this.selected);
        this.setSnackbar("Updated products cart", "success");
        this.selected = [];
      }
    },

    openNewProductDialog() {
      this.$store.commit("setNewProductDialog", true);
    },

    async getProducts() {
      var branchId = this.$route.params.branchId;

      await this.$store.dispatch("getProducts", branchId);
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  watch: {
    $route() {
      this.getProducts();
    }
  },

  mounted() {
    this.getProducts();
  },

  computed: {
    branchNotFound() {
      return this.$store.state.branches.branchNotFound;
    },

    products() {
      return this.$store.getters.products;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    isSmaller() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs" || name === "sm") return true;
      else return false;
    }
  }
};
</script>

<style scoped>
.showLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>