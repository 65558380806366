<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    persistent
    transition="dialog-transition"
    max-width="500px"
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">New Staff</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="mr-2 text-none primary darken-4" @click="createStaff()">Create</v-btn>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-form class="pa-4" ref="form" @submit.prevent v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            autofocus
            label="Name"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            validate-on-blur
          ></v-text-field>

          <v-text-field
            type="text"
            v-model="username"
            :rules="usernameRules"
            label="Staff username"
            :counter="50"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            validate-on-blur
            dense
            outlined
          ></v-text-field>

          <v-text-field
            v-model="phone"
            :rules="phoneRules"
            label="Phone"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            prefix="+233"
            counter="9"
          ></v-text-field>

          <v-textarea
            v-model="description"
            label="Description"
            color="primary darken-4"
            :rows="1"
            outlined
            dense
            auto-grow
            hint="Providing a staff description could be helpful."
            persistent-hint
          ></v-textarea>

          <v-text-field
            v-model="branchId"
            label="Branch (ID)"
            :rules="branchRules"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            hint="Goto a Branch to get its ID to proceed. (Uneditable)"
            persistent-hint
            readonly
          ></v-text-field>
        </v-form>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewStaffDialog",
  data() {
    return {
      valid: true,
      name: "",
      nameRules: [v => !!v || "name is required"],
      username: "",
      usernameRules: [
        v => !!v || "Staff username is required",
        v =>
          !v ||
          (v && v.length <= 50) ||
          "Staff username must be at most 50 characters"
      ],
      phone: "",
      phoneRules: [
        v => !!v || "Enter an active phone number",
        v => !isNaN(v) || "Phone number must be valid",
        v => (v && v.length === 9) || "Phone number must be 9 digits"
      ],
      description: "",
      branchRules: [v => !!v || "Branch ID is required. Goto a Branch"]
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setNewStaffDialog", false);
    },

    async createStaff() {
      if (this.$refs.form.validate()) {
        var data = {
          branch: this.branchId,
          name: this.name,
          username: this.username,
          phone: this.phone,
          description: this.description
        };

        await this.$store.dispatch("createStaff", data).then(() => {
          this.$refs.form.reset();
        });
      }
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.staff.newStaffDialog;
    },

    branchId: {
      get() {
        if (this.$route.params.branchId) return this.$route.params.branchId;
        else return "";
      },

      set: function() {
        return;
      }
    }
  }
};
</script>

<style>
</style>