<template>
  <v-dialog
    v-model="showDialog"
    persistent
    scrollable
    transition="dialog-transition"
    max-width="500px"
  >
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">New Product</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="mr-2 text-none primary darken-4" @click="addProduct()">Add</v-btn>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-form class="pa-4" ref="form" @submit.prevent v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            autofocus
            label="Name"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            validate-on-blur
          ></v-text-field>

          <v-text-field
            type="number"
            v-model.number="price"
            :rules="priceRules"
            prefix="GH₵"
            label="Price"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            validate-on-blur
          ></v-text-field>

          <v-row>
            <v-col align="center" cols="2">
              <v-checkbox
                v-model="isQuantifiable"
                color="primary darken-4"
                hide-details
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col align="center" cols="10">
              <v-text-field
                type="number"
                v-model.number="quantity"
                :rules="quantityRules"
                label="Quantity"
                hint="Check the box to the left, if this product is quantifiable."
                persistent-hint
                color="primary darken-4"
                :class="{ 'mb-3': !valid }"
                outlined
                dense
                validate-on-blur
                :disabled="!isQuantifiable"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-textarea
            v-model="description"
            label="Description"
            color="primary darken-4"
            validate-on-blur
            hide-details
            :rows="1"
            outlined
            dense
            auto-grow
          ></v-textarea>
        </v-form>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { tokenConfig, redirectToLogin } from "@/helpers/auth";

export default {
  name: "NewProductDialog",
  data() {
    return {
      valid: true,
      name: "",
      nameRules: [v => !!v || "name is required"],
      price: 0.0,
      priceRules: [
        v => !!v || "Update price",
        v => v > 0 || "Price must be greater than 0"
      ],
      isQuantifiable: true,
      quantity: 0,
      description: ""
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setNewProductDialog", false);
      this.$refs.form.reset();
    },

    async addProduct() {
      if (this.$refs.form.validate()) {
        var product = {
          branch: this.$route.params.branchId,
          name: this.name,
          price: this.price,
          is_quantifiable: this.isQuantifiable,
          quantity: this.quantity,
          description: this.description
        };
        const state = this.$store.state;

        await axios
          .post("/api/v1/products/product/", product, tokenConfig(state))
          .then(() => {
            this.setSnackbar("Product was added successfully", "success");
            this.closeDialog();
            this.$store.dispatch("getProducts", this.$route.params.branchId);
          })
          .catch(error => {
            if (error.response.status == 401)
              redirectToLogin(this.$router, this.$store);
            else this.$store.commit("handleError", error);
          });
      }
    },

    setSnackbar(msg, color) {
      var payload = {
        state: true,
        color: color,
        comment: msg
      };
      this.$store.commit("setSnackbar", payload);
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.products.newProductDialog;
    },

    quantityRules() {
      const rules = [];

      if (this.isQuantifiable) {
        const rule1 = v => v !== "" || "Enter a number";
        rules.push(rule1);
        const rule2 = v => v >= 0 || "Quantity must be a non-negative number";
        rules.push(rule2);
      }

      return rules;
    }
  }
};
</script>

<style>
</style>