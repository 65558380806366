<template>
  <div>
    <div @click="launchConfirmDialog()">
      <slot name="activator"></slot>
    </div>

    <!-- confirm dialog makes user confirm delete -->
    <v-dialog v-model="confirmDialog" max-width="200">
      <v-card>
        <v-card-text class="pt-2 pb-2 body-1">Remove product?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="grey--text font-weight-bold body-2 text-capitalize"
            @click="cancelDelete()"
            text
          >Cancel</v-btn>
          <v-btn
            class="font-weight-bold body-2 text-capitalize"
            color="error"
            @click="confirmDelete()"
            text
          >Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "OrderProductDelete",
  data() {
    return {
      confirmDialog: false,
      confirmed: false
    };
  },
  props: {
    // Use "value" to enable using v-model
    value: Object
  },
  methods: {
    launchConfirmDialog() {
      this.confirmDialog = true;
    },
    cancelDelete() {
      this.confirmed = false;
      this.confirmDialog = false;
      this.$emit("input", { confirmed: this.confirmed });
    },
    confirmDelete() {
      this.confirmed = true;
      this.confirmDialog = false;
      this.$emit("input", { confirmed: this.confirmed });
    }
  }
};
</script>