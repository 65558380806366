<template>
  <v-dialog v-model="showDialog" persistent transition="dialog-transition" max-width="500px">
    <v-card tile>
      <v-toolbar dense>
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title class="subtitle-1">New Order</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn small class="my-auto mx-2" icon @click="sendOrder()">
          <v-icon color="primary darken-4">mdi-send</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mx-0 px-0">
        <v-form class="pa-4" ref="form" @submit.prevent v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            autofocus
            label="Name"
            color="primary darken-4"
            append-icon="mdi-head-lightbulb"
            @click:append="generateName()"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            clearable
          ></v-text-field>

          <v-text-field
            type="number"
            v-model="phone"
            :rules="phoneRules"
            label="Phone"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            prefix="+233"
            counter="9"
          ></v-text-field>

          <v-text-field
            v-model="branchId"
            label="Branch (ID)"
            :rules="branchRules"
            color="primary darken-4"
            :class="{ 'mb-3': !valid }"
            outlined
            dense
            hint="Goto a Branch to get its ID to proceed. (Uneditable)"
            persistent-hint
            readonly
          ></v-text-field>
        </v-form>

        <div class="text-center font-italic">
          All items in your cart will be used in this
          <span class="font-weight-bold">order</span>.
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NewOrderDialog",
  data() {
    return {
      valid: true,
      isSending: false,
      name: "",
      nameRules: [v => !!v || "name is required"],
      phone: "",
      phoneRules: [
        v => !isNaN(v) || "Phone number must be valid",
        v =>
          (v && v.length === 9) || v === "" || "Phone number must be 9 digits"
      ],
      branchRules: [v => !!v || "Branch ID is required. Goto a Branch"]
    };
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setNewOrderDialog", false);
    },

    getRandomInt(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    },

    generateText(length) {
      const base = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];

      const generator = (base, len) => {
        return [...Array(len)]
          .map(() => base[(Math.random() * base.length) | 0])
          .join("");
      };
      return generator(base, length);
    },

    generateName() {
      const zeroPad = (num, places) => String(num).padStart(places, "0");

      this.name = `${this.generateText(3)}-${zeroPad(
        this.getRandomInt(0, 99999),
        5
      )}`;
    },

    sendOrder() {
      if (this.$refs.form.validate()) {
        var data = {
          branch: this.$route.params.branchId,
          name: this.name,
          client_phone: this.phone,
          amount_payable: this.$store.getters.productsCartInfo.sum,
          order_items: this.$store.state.carts.products.map(product => {
            product["item_id"] = product["id"];

            return product;
          })
        };
        this.$store.dispatch("sendOrder", data).then(response => {
          this.$router.push({
            name: "order",
            params: { orderId: response.data.id }
          });
          this.$refs.form.reset();
        });
      }
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.orders.newOrderDialog;
    },

    branchId: {
      get() {
        if (this.$route.params.branchId) return this.$route.params.branchId;
        else return "";
      },

      set: function() {
        return;
      }
    }
  }
};
</script>

<style>
</style>