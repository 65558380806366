<template>
  <v-dialog v-model="showDialog" persistent transition="dialog-transition" max-width="290">
    <v-card>
      <v-card-title class="headline">Empty Cart</v-card-title>
      <v-card-text>
        Add at least a product to your
        <span class="font-weight-bold">Cart</span>
        to continue
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="primary darken-4" @click="closeDialog()">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EmptyCartDialog",
  data() {
    return {};
  },

  components: {},

  methods: {
    closeDialog() {
      this.$store.commit("setEmptyCartDialog", false);
    }
  },

  watch: {},

  computed: {
    showDialog() {
      return this.$store.state.carts.emptyCartDialog;
    }
  }
};
</script>

<style>
</style>