import Vue from "vue";

export default {
  state() {
    return {
      products: [],

      productsCartDialog: false,
      emptyCartDialog: false,
    };
  },

  getters: {
    productsCartInfo(state) {
      return {
        numberOfProducts: state.products.length.toString(),
        sum: state.products.reduce(function(accumulator, product) {
          return accumulator + Number(product.price) * Number(product.quantity);
        }, 0),
      };
    },
  },

  mutations: {
    setProductsCartDialog(state, status = null) {
      state.productsCartDialog = status;
    },

    setEmptyCartDialog(state, status = null) {
      state.emptyCartDialog = status;
    },

    addToProductsCart(state, payload) {
      var payloadClone = JSON.parse(JSON.stringify(payload));
      var newCartProducts = JSON.parse(JSON.stringify(payload));

      for (var product of payloadClone) {
        var duplicate = state.products.filter(
          (cartProduct) => cartProduct.id === product.id
        );
        if (duplicate.length) {
          Vue.set(duplicate[0], "quantity", duplicate[0].quantity + 1);

          newCartProducts
            .filter((product) => product.id === duplicate[0].id)
            .map((product) =>
              newCartProducts.splice(newCartProducts.indexOf(product), 1)
            );
        }
      }

      var newProducts = newCartProducts.map(function(product) {
        product.quantity = 1;

        return product;
      });

      state.products.push(...newProducts);
    },

    increaseCartItem(state, payload) {
      var item = state.products.filter((product) => product.id === payload)[0];
      Vue.set(item, "quantity", item.quantity + 1);
    },

    decreaseCartItem(state, payload) {
      var item = state.products.filter((product) => product.id === payload)[0];
      Vue.set(item, "quantity", item.quantity - 1);
    },

    deleteCartProducts(state, payload) {
      function isProduct(cartProduct) {
        return cartProduct.id === this.id;
      }

      payload.filter((selectedProduct) => {
        const index = state.products.findIndex(isProduct, selectedProduct);
        state.products.splice(index, 1);
      });
    },

    clearCartProducts(state) {
      state.products = [];
    },
  },

  actions: {},
};
