<template>
  <div class="order">
    <template v-if="!orderNotFound">
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="auto">
            <v-card color="white" class="pb-2" light elevation="2" max-width="600">
              <v-subheader>Order Details</v-subheader>

              <v-container class="mt-0" fluid>
                <v-row>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Name: {{ order.name }}</span>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Order ID: {{ order.id }}</span>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <span class="body-2">Attendant: {{ order.attendant }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col class="py-0 px-0 text-center font-weight-bold" offset="9">GH₵</v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0 body-2" cols="9">Amount Payable</v-col>
                      <v-col class="py-0 px-0 text-center" cols="3">{{ order.amount_payable }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <div class="d-flex mr-4 mb-3">
                <v-btn
                  depressed
                  small
                  class="primary darken-4 text-none mb-2 ml-2"
                  @click="addProducts()"
                >
                  <v-icon>mdi-cart-arrow-right</v-icon>Order Cart
                </v-btn>

                <v-spacer></v-spacer>

                <v-fade-transition leave-absolute>
                  <v-btn v-if="pendingOrder" @click="unsetPendingOrder()" icon>
                    <v-icon color="grey">mdi-sync</v-icon>
                  </v-btn>

                  <v-btn @click="GotoSale()" icon v-else>
                    <v-icon color="success">mdi-text-box-check</v-icon>
                  </v-btn>
                </v-fade-transition>

                <order-delete v-model="removeOrder">
                  <template #activator>
                    <v-btn icon>
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </order-delete>
              </div>

              <div class="mx-4">
                <v-data-table
                  :fixed-header="true"
                  :headers="headers"
                  :items="orderItems"
                  item-key="id"
                  class="elevation-1"
                  max-height="65vh"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      class="showLink"
                      text
                      :ripple="false"
                      :to="{ name: 'product', params: { productId: item.item_id } }"
                    >
                      <v-icon color="primary lighten-1">mdi-arrow-expand</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.is_pending="{ item }">
                    <v-fade-transition leave-absolute>
                      <v-icon
                        v-if="!item.is_pending"
                        class="mt-0"
                        color="success"
                      >mdi-check-circle-outline</v-icon>

                      <v-btn @click="setItemStatus(item.id)" icon v-else>
                        <v-icon color="grey" class="mt-0">mdi-sync</v-icon>
                      </v-btn>
                    </v-fade-transition>
                  </template>

                  <template v-slot:item.delete="{ item }">
                    <order-product-delete v-model="removeProduct">
                      <template #activator>
                        <v-btn @click="setOrderItemId(item.id)" icon>
                          <v-icon color="error">mdi-close-circle</v-icon>
                        </v-btn>
                      </template>
                    </order-product-delete>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Order</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import OrderDelete from "@/components/orders/OrderDelete";
import OrderProductDelete from "@/components/orders/OrderProductDelete";

export default {
  name: "Order",
  data() {
    return {
      removeOrder: null,
      itemId: "",
      removeProduct: null,
      headers: [
        { text: "", value: "actions", sortable: false },
        { text: "Name", align: "start", value: "name" },
        { text: "Price (GH₵)", value: "price" },
        { text: "Quantity", align: "center", value: "quantity" },
        { text: "Status", value: "is_pending", sortable: false },
        { text: "", value: "delete", sortable: false }
      ]
    };
  },

  components: {
    OrderDelete,
    OrderProductDelete
  },

  methods: {
    addProducts() {
      this.$store.dispatch("updateExistingOrder");
    },

    async getOrder() {
      var orderId = this.$route.params.orderId;

      await this.$store.dispatch("getOrder", orderId);
    },

    async unsetPendingOrder() {
      var orderId = this.$route.params.orderId;

      await this.$store.dispatch("readyOrder", orderId);
    },

    GotoSale() {
      this.$store.commit("setNewSaleDialog", true);
    },

    async deleteOrder() {
      if (this.removeOrder && this.removeOrder.confirmed) {
        var orderId = this.$route.params.orderId;

        await this.$store
          .dispatch("deleteOrder", orderId)
          .then(() => {
            this.$router.push({ name: "home" });
          })
          .catch(() => {});
      }
    },

    async setItemStatus(itemId) {
      var orderId = this.$route.params.orderId;

      await this.$store
        .dispatch("setItemStatus", { orderId, itemId: itemId })
        .then(() => {})
        .catch(() => {});
    },

    setOrderItemId(id) {
      this.itemId = id;
    },

    async deleteProduct() {
      if (this.removeProduct && this.removeProduct.confirmed) {
        var orderId = this.$route.params.orderId;

        await this.$store
          .dispatch("deleteOrderItem", { orderId, itemId: this.itemId })
          .then(() => {})
          .catch(() => {});
      }
    }
  },

  watch: {
    $route() {
      this.getOrder();
    },

    removeOrder: "deleteOrder",
    removeProduct: "deleteProduct"
  },

  mounted() {
    this.getOrder();
  },

  computed: {
    order() {
      return this.$store.state.orders.order;
    },

    orderItems() {
      return this.$store.state.orders.order.order_items;
    },

    orderNotFound() {
      return this.$store.state.orders.orderNotFound;
    },

    pendingOrder() {
      if (this.$store.state.orders.order.order_items) {
        var pending = this.$store.state.orders.order.order_items.filter(
          item => item.is_pending === true
        );

        return pending.length > 0;
      } else return null;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    }
  }
};
</script>

<style scoped>
.showLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>