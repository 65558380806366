<template>
  <div class="profits-vs-costs-groups">
    <template v-if="!branchNotFound">
      <v-list v-if="groups.length > 0" two-line>
        <v-list-item-group>
          <template v-for="(group, index) in groups">
            <v-list-item
              :key="group.id"
              :to="{name: 'profits-vs-costs-group', params: { groupId: group.id } }"
            >
              <v-list-item-content>
                <v-list-item-title class="headline" v-text="group.name"></v-list-item-title>
                <v-list-item-subtitle class="text--primary" v-text="group.description"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-text="group.created"></v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <v-divider v-if="index + 1 < groups.length" :key="index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>

      <v-container v-else>
        <v-row justify="center">
          <v-col cols="auto">
            <div class="grey--text body-1">No Groups. Add group(s)</div>
          </v-col>
        </v-row>
      </v-container>

      <v-fab-transition>
        <v-btn
          v-show="isSmaller"
          dark
          color="primary darken-4"
          fixed
          bottom
          right
          fab
          @click="openNewGroupDialog()"
        >
          <v-icon large>mdi-select-group</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-card color="white" class="pa-8" light elevation="2" :width="cardWidth">
            <div class="d-flex justify-center grey--text">
              <v-icon size="150">mdi-cancel</v-icon>
            </div>

            <div class="text-center title grey--text">
              <span class="font-weight-black">Branch</span> not available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ProfitsVsCostsGroups",

  data: () => ({}),

  components: {},

  methods: {
    openNewGroupDialog() {
      this.$store.commit("setNewGroupDialog", true);
    },

    async getGroups() {
      var branchId = this.$route.params.branchId;

      await this.$store.dispatch("getGroups", branchId);
    }
  },

  watch: {
    $route() {
      this.getGroups();
    }
  },

  mounted() {
    this.getGroups();
  },

  computed: {
    branchNotFound() {
      return this.$store.state.branches.branchNotFound;
    },

    groups() {
      return this.$store.state.analyses.groups;
    },

    cardWidth() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs") {
        return "99vw";
      } else {
        return "45vw";
      }
    },

    isSmaller() {
      var name = this.$vuetify.breakpoint.name;

      if (name === "xs" || name === "sm") return true;
      else return false;
    }
  }
};
</script>

<style scoped>
</style>
