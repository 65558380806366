<template>
  <v-app>
    <Header />

    <v-main>
      <router-view />

      <ProductsCartDialog />
      <ProductsOrderDialog />
      <NewOrderDialog />
      <NewSaleDialog />
      <EmptyCartDialog />
      <NewStaffDialog />
      <NewBranchDialog />
      <NewProductDialog />
      <NewGroupDialog />
      <AddGroupProductDialog />
      <EditGroupItemCostDialog />
      <EditGroupTimelineDialog />
      <GroupAnalysesDialog />
    </v-main>

    <v-snackbar
      app
      v-model="snackbarStatus"
      transition="fade-transition"
      :color="snackbarColor"
      multi-line
      bottom
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" dark class="text-none" text @click="closeSnackbar">Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";

import ProductsCartDialog from "@/components/carts/ProductsCartDialog";
import ProductsOrderDialog from "@/components/orders/ProductsOrderDialog";
import NewOrderDialog from "@/components/orders/NewOrderDialog";
import NewSaleDialog from "@/components/sales/NewSaleDialog";
import EmptyCartDialog from "@/components/carts/EmptyCartDialog";
import NewStaffDialog from "@/components/staff/NewStaffDialog";
import NewBranchDialog from "@/components/branches/NewBranchDialog";
import NewProductDialog from "@/components/products/NewProductDialog";
import NewGroupDialog from "@/components/analyses/NewGroupDialog";
import AddGroupProductDialog from "@/components/analyses/AddGroupProductDialog";
import EditGroupItemCostDialog from "@/components/analyses/EditGroupItemCostDialog";
import EditGroupTimelineDialog from "@/components/analyses/EditGroupTimelineDialog";
import GroupAnalysesDialog from "@/components/analyses/GroupAnalysesDialog";

export default {
  name: "App",

  components: {
    Header,
    // Footer,

    ProductsCartDialog,
    ProductsOrderDialog,
    NewOrderDialog,
    NewSaleDialog,
    EmptyCartDialog,
    NewStaffDialog,
    NewBranchDialog,
    NewProductDialog,
    NewGroupDialog,
    AddGroupProductDialog,
    EditGroupItemCostDialog,
    EditGroupTimelineDialog,
    GroupAnalysesDialog
  },

  data: () => ({}),

  methods: {
    closeSnackbar() {
      this.$store.commit("setSnackbar", { state: false });
    }
  },

  mounted() {},

  computed: {
    snackbarStatus: {
      get: function() {
        return this.$store.state.snackbar;
      },
      set: function(state) {
        this.$store.commit("setSnackbar", { state: state });
      }
    },
    snackbarColor() {
      return this.$store.state.snackbarColor;
    },
    snackbarMessage() {
      return this.$store.state.snackbarMessage;
    }
  }
};
</script>

<style>
</style>
